import React, {useState, useContext, useEffect} from "react";
import { UserContext } from "../context/UserProvider";

export default function AccountCreateForm(){

    const initInputs = {
        email:'',
        password:'',
        firstName:'',
        lastName:'',
        address:{
            line1:'',
            line2:'',
            city:'',
            state:'',
            zip:''
        }
    }

    const [inputs, setInputs] = useState(initInputs)
    const [showPass, setShowPass] = useState(false)

    const [passwordMatchMessage, setPasswordMatchMessage] = useState('')

    const [confirmation, setConfirmation] = useState({
        confirmPassword:''
    })

    const {signup} = useContext(UserContext)

    function ToggleShow(){
        setShowPass(prevShowPass => !prevShowPass )
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    window.document.body.style.zoom = 1;

    function handleChange(e) {
        const { name, value } = e.target;
              
        if(name === 'firstName' || name ==='lastName' || name ==='email' || name ==='password'){
                setInputs((prevInputs) => (
                    { ...prevInputs, [name]: value}
                ));
        }else if(name === 'line1'||name ==='line2'||name ==='city'||name ==='state'||name ==='zip'){
                setInputs((prevInputs) => (    
                    {...prevInputs, address:{...prevInputs.address, [name]: value}}
                ));
        }
    }

    function handleConfirmationChange(e){
        const { name, value } = e.target;
        setConfirmation((prevConfirmation) => (
            { ...prevConfirmation, [name]: value}
        ));
    }

      function handleSubmit(e) {
        e.preventDefault();
        if(confirmation.confirmPassword === inputs.password && inputs.password !== ''){
            signup(inputs) 
        }
        else{
            setPasswordMatchMessage('Password Fields Must Match')
        }
      }

    return(
        <div>
            <form onSubmit={handleSubmit} className = 'accountCreateForm'>
                <div>
                    <div>First Name</div>
                    <input
                        placeholder="First Name"
                        autoFocus
                        type="text"
                        name='firstName'
                        value={inputs.firstName}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Last Name</div>
                    <input
                        placeholder="Last Name"
                        type="text"
                        name='lastName'
                        value={inputs.lastName}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Address Line 1</div>
                    <input
                        placeholder="Address Line 1"
                        type="text"
                        name='line1'
                        value={inputs.address.line1}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Address Line 2</div>
                    <input
                        placeholder="Address Line 2"
                        type="text"
                        name='line2'
                        value={inputs.address.line2}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>City</div>
                    <input
                        placeholder="City"
                        type="text"
                        name='city'
                        value={inputs.address.city}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>State</div>
                    <input
                        placeholder="State"
                        type="text"
                        name='state'
                        value={inputs.address.state}
                        onChange={handleChange}
                    />
                </div>
                {// sent number required in server but sending text
                }
                <div>
                    <div>Zip Code</div>
                    <input
                        placeholder="Zip Code"
                        type="text"
                        name='zip'
                        value={inputs.address.zip}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Email Address</div>
                    <input
                        placeholder="Email Address"
                        type="text"
                        name='email'
                        value={inputs.email}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Set aPassword</div>
                    <input
                        placeholder="Set Password"
                        type={showPass ? "text" : "password"}
                        name='password'
                        value={inputs.password}
                        onChange={handleChange}
                    />
                    <div onClick={ToggleShow} style={{
                        display: 'inline-block',
                        color: 'black',
                        marginLeft: -40,
                        height: 20,
                        paddingTop: 10,
                        fontSize: 12
                        }}>
                        {showPass ? 'Hide' : 'Show'}
                    </div>
                </div>
                <div>
                    <div>Confirm Password</div>
                    <input
                        placeholder="Confirm Password"
                        type={showPass ? "text" : "password"}
                        name='confirmPassword'
                        value={confirmation.confirmPassword}
                        onChange={handleConfirmationChange}
                    />
                    <div onClick={ToggleShow} style={{
                        display: 'inline-block',
                        color: 'black',
                        marginLeft: -40,
                        height: 20,
                        paddingTop: 10,
                        fontSize: 12
                        }}>
                        {showPass ? 'Hide' : 'Show'}
                    </div>
                </div>
                <div style={{color: 'red'}}>{passwordMatchMessage}</div>
                <button>Submit</button>
            </form>
        </div>
    )
}