import React, {useContext, useState, useRef} from "react";
import axios from "axios";
import { UserContext } from "../context/UserProvider";

export default function WorkRequestByAdmin(props){
    const{
        connectServerString, 
        transProtocol,
        jobTypeArray,
        loadingOn,
        handleAuthErr,
        handleResMsg
    } = useContext(UserContext)

    const userUploadAxios = axios.create()

    userUploadAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        
        config.headers = {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
        return config
    })

    const [altInputs, setAltInputs] = useState({
        altJobType: ''
    })

    const [inputs, setInputs] = useState({
        jobType: '',
        description: '',
        jobLocation: {
            line1:'',
            line2:'',
            city:'',
            state:'',
            zip:''
        },
        poc: {
            contactFirstName: '',
            contactLastName: '',
            contactPhone: '',
            contactEmail: ''
        }
    })

    function handleAltChange(e){
        const { name, value } = e.target;
        setAltInputs((prevInputs) => (
            { ...prevInputs, [name]: value}
        ));
    }
    // eslint-disable-next-line
    const [file, setFile] = useState(null)

    const formRef = useRef(null)

    function requestWork(workRequest){
        loadingOn()
        userUploadAxios.post(`${transProtocol}://${connectServerString}/api/access/workbyadmin/${props.userView._id}`, workRequest)
        .then((res => {
            handleResMsg(res.data)
        }))
        .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    function checkFileType(file){
        if(
            file.type === 'image/jpeg'
            ||
            file.type === 'image/png'
            ||
            file.type === 'application/pdf'
        ){
            return true
        }else{
            return false
        }
    }

    function handleChange(e) {
        const { name, value, files } = e.target;
        if(name === 'jobType' || name ==='description'){
            setInputs((prevInputs) => (
                { ...prevInputs, [name]: value}
            ));
        }else if(name === 'line1'||name ==='line2'||name ==='city'||name ==='state'||name ==='zip'){
                setInputs((prevInputs) => (    
                    {...prevInputs, jobLocation:{...prevInputs.jobLocation, [name]: value}}
                ));
        }else if(name === 'contactFirstName'||name ==='contactLastName'||name ==='contactPhone'||name ==='contactEmail'){
            setInputs((prevInputs) => (    
                {...prevInputs, poc:{...prevInputs.poc, [name]: value}}
            ));
        }else if(name ==='imgUrl'){
            if(checkFileType(files[0])){
                setFile(files[0])
            }else{
                alert('upload will fail with that file. jpg, png, or pdf only')
            }
        }
    }

    function handleSubmit(e){
        e.preventDefault()
        const data = new FormData(formRef.current)
        
        for (var pair of data.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }

        data.entries()
        props.toggleRequest()
        requestWork(data)
    }

    return(
        <div className='workReqByAdmin'>
            
            <form ref={formRef} onSubmit={handleSubmit} className='requestForm' encType="multipart/form-data">
                <div className='requestField'>
                    <div>Job Type</div>
                    <select name = 'jobType' onChange={handleChange} value = {inputs.jobType} autoFocus>
                        {jobTypeArray.map((type, i)=>(
                            <option key={i} value = {type}>{type}</option>
                        ))}
                    </select>
                    {inputs.jobType === 'Other' 
                        ? 
                        <div>
                            <div>
                                Best Described as
                                <div style={{fontSize: 13}}>(25 chars)</div>
                            </div>
                            <input
                                name='altJobType'
                                maxLength={25}
                                onChange={handleAltChange}
                                value={altInputs.altJobType}
                            />
                        </div> 
                        : 
                        ''
                    }
                </div>
                    <div style={{marginLeft: -60} }>Job Location</div>
                    <div className='jobLocationField'>
                        <div>Address Line 1</div>
                        <input
                            placeholder="Address Line 1"
                            type="text"
                            name='line1'
                            value={inputs.jobLocation.line1}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>Address Line 2</div>
                        <input
                            placeholder="Address Line 2"
                            type="text"
                            name='line2'
                            value={inputs.jobLocation.line2}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>City</div>
                        <input
                            placeholder="City"
                            type="text"
                            name='city'
                            value={inputs.jobLocation.city}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>State</div>
                        <input
                            placeholder="State"
                            type="text"
                            name='state'
                            value={inputs.jobLocation.state}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>Zip Code</div>
                        <input
                            placeholder="Zip Code"
                            type="text"
                            name='zip'
                            value={inputs.jobLocation.zip}
                            onChange={handleChange}
                        />
                    </div>


                    <div style={{marginLeft: -60} }>Point Of Contact</div>
                    <div className='jobLocationField'>
                        <div>Contact's First Name</div>
                        <input
                            placeholder="Contact's First Name"
                            type="text"
                            name='contactFirstName'
                            value={inputs.poc.contactFirstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>Contact's Last Name</div>
                        <input
                            placeholder="Contact's Last Name"
                            type="text"
                            name='contactLastName'
                            value={inputs.poc.contactLastName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>Contact's Phone</div>
                        <div style={{fontSize: 10}}>format of: '555-555-5555'</div>
                        <input
                            placeholder="Contact's Phone Number"
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            name='contactPhone'
                            value={inputs.poc.contactPhone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>Contact's Email</div>
                        <input
                            placeholder="Contact's Email"
                            type="email"
                            name='contactEmail'
                            value={inputs.poc.contactEmail}
                            onChange={handleChange}
                        />
                    </div>



                <div className='requestField'>
                    <div>Description</div>
                    <textarea
                        className='requestDescriptionField'
                        placeholder="Description"
                        type="textarea"
                        name='description'
                        maxLength={200}
                        value={inputs.description}
                        onChange={handleChange}
                    />
                </div>
                <div className='requestField'>
                    <div>Image File (png, jpg)</div>
                    <input
                        placeholder="Image URL"
                        type="file"
                        name='imgUrl'
                        value={inputs.imgUrl}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <button className='requestBtn'>Submit Request</button>
                </div>
                
            </form>
        </div>
    )
}