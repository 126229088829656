import React, { useState, useContext, useEffect} from 'react'
import axios from 'axios'
import {UserContext} from '../context/UserProvider.js'
import Profile from './Profile.js'
import AccountRequestsView from './AccountRequestsView'
import WorkRequest from './WorkRequest.js'
//import AdminJob from './AdminJob.js'
//import AdminJobUpdated from './AdminJobUpdated.js'
import Scheduler from './Scheduler.js'
import EmailVerification from './EmailVerification.js'
import PasswordChangeForm from './PasswordChangeForm.js'
import JobSearch from './JobSearch.js'
//import GetAllReportJob from './GetAllReportJob.js'
import Report from './Report.js'
//import UserCreatedUser from './UserCreatedUser.js'
import UserManager from './UserManager.js'


const userAxios = axios.create()

userAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = `Bearer ${token}`
    return config
})

export default function Account(){

  const [scheduleOpen, setScheduleOpen] = useState(false)
  const [updatingAddress, setUpdatingAddress] = useState(false)
  const [requestDisplayed, setRequestDisplayed] = useState(false)
  const [myRequestsDisplayed, setMyRequestsDisplayed] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [userManagement, setUserManagement] = useState(false)
  const [addressInputs, setAddressInputs] = useState({
    address: {
      line1:'',
      line2:'',
      city:'',
      state:'',
      zip:''
    }
  })

  function handleAddressChange(e){
    const { name, value } = e.target;
        
        if(name === 'line1'||name ==='line2'||name ==='city'||name ==='state'||name ==='zip'){
            setAddressInputs((prevInputs) => (    
                {...prevInputs, address:{...prevInputs.address, [name]: value}}
            ));
        }
  }

  function handleAddressSubmit(e){
    e.preventDefault()
    updateAddress(addressInputs)
    toggleUpdatingAddress()
  }

  function toggleUpdatingAddress(){
    setUpdatingAddress(prevState => !prevState)
  }

  function toggleUserManagement(){
    setMyRequestsDisplayed(prevState => (
      prevState = false
    ))
    setSearchOpen(prevState => (
      prevState = false
    ))
    setRequestDisplayed(prevState => (
      prevState = false
    ))
    setUserManagement(prevState => (
      prevState = !prevState
    ))
  }

  function toggleRequestDisplayed(){
    setMyRequestsDisplayed(prevState => (
      prevState = false
    ))
    setSearchOpen(prevState => (
      prevState = false
    ))
    setRequestDisplayed(prevState => (
      prevState = !prevState
    ))
  }

  function toggleSearch(){
    setMyRequestsDisplayed(prevState => (
      prevState = false
    ))

    setIsRequested(prevIsRequested => (
      prevIsRequested =false
    ))

    setSearchOpen(prevState => (
      prevState = !prevState
    ))
  }

  function toggleScheduleDisplay(){
    setIsRequested(prevIsRequested => false)
    setScheduleOpen(prevState => (
      prevState = !prevState
    ))
  }

  function toggleMyRequestsDisplayed(){
    setRequestDisplayed(prevState => (
      prevState = false
    ))
    getUsersRequests()
    setMyRequestsDisplayed(prevState => (
      prevState = !prevState
    ))
  }

  const { 
    user,
    logout,
    updateAddress,
    userRequests,
    getUsersRequests,
    connectServerString,
    socket,
    transProtocol,
    getMembers
  } = useContext(UserContext)

  const [scheduledEvents, setScheduledEvents] = useState([])
  const [isRequested, setIsRequested] = useState(false)

  function toggleRequested(){
    setScheduleOpen(prevState => (
      prevState = false
    ))
    setSearchOpen(prevState => (
      prevState = false
    ))
    setIsRequested(prevIsRequested => !prevIsRequested)
  }

  useEffect(() => {
    if(scheduleOpen){
      socket.on('updateSchedule', () =>{
        
          userAxios.get(`${transProtocol}://${connectServerString}/api/access/schedulerwork`)
            .then(res => {
              setScheduledEvents(prevScheduledEvents => (
                [
                  ...res.data
                ]))
            })
            .catch(err => 
              console.log(err))
        
      })
      userAxios.get(`${transProtocol}://${connectServerString}/api/access/schedulerwork`)
      .then(res => {
        setScheduledEvents(prevScheduledEvents => (
          [
            ...res.data
          ]))
      })
      .catch(err => 
        console.log(err))
      return () => {
        socket.off('updateSchedule')
      }
    }else{
      setScheduledEvents(prevUserList => [])
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scheduleOpen])

  useEffect(() => {
    if(user.access === 'admin'){
      getMembers()
    }
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   if(scheduleOpen){
  //     userAxios.get(`${transProtocol}://${connectServerString}/api/access/work`)
  //     .then(res => {
  //       setScheduledEvents(prevScheduledEvents => (
  //         [
  //           ...res.data
  //         ]))
  //     })
  //     .catch(err => 
  //       console.log(err))
  //   }else{
  //     setScheduledEvents(prevUserList => [])
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[scheduleOpen])

  const displayedEvents = scheduledEvents.map(scheduledEvent => {

    let inspectToIso = new Date(scheduledEvent.inspectionDate)
    let inspectDate = ''
    let inspectEndDate = ''
    let startToIso = new Date(scheduledEvent.estimatedStart)
    let startDate = ''
    let endToIso = new Date(scheduledEvent.estimatedFinish)
    let endDate = ''
    let isCompleted = scheduledEvent.completedActual ? true : false
    
    if(scheduledEvent.inspectionDate){
      inspectToIso = inspectToIso.toISOString()
      let splitinspectDate = inspectToIso.split('T')[0]
      inspectDate  = new Date(Date.parse(`${splitinspectDate}T00:00:00.000-08:00`))
      inspectEndDate  = new Date(Date.parse(`${splitinspectDate}T00:00:00.000-12:00`))
    }
    if(scheduledEvent.estimatedStart){
      startToIso = startToIso.toISOString()
      let splitStartDate = startToIso.split('T')[0]
      startDate  = new Date(Date.parse(`${splitStartDate}T00:00:00.000-08:00`))
    }
    if(scheduledEvent.estimatedFinish){
      endToIso = endToIso.toISOString()
      let splitEndDate = endToIso.split('T')[0]
      endDate  = new Date(Date.parse(`${splitEndDate}T00:00:00.000-08:00`))
    }
    return(
      {
        title: scheduledEvent.jobType,
        start: isCompleted ? '' : startDate === ''&& endDate === '' ? inspectDate : startDate,
        end: isCompleted ? '' : startDate === ''&& endDate === '' ? inspectEndDate : endDate,
        jobType: scheduledEvent.jobType,
        status: scheduledEvent.status,
        isForInspection: startDate === ''&& endDate === '' ? true : false ,
        _id: scheduledEvent._id
      }
    )
})

  if(user.isVerified && !user.tempRequested){
  
    return (
      <div className="Account">
          <div>
            <h1 className='nameDisplay'>Welcome {user.firstName}!</h1>
            <h2 style={{color: 'white'}}>{user.isVerified}</h2>
            <Profile user = {user}/>
            {user.access === 'member' || 'admin' ?
              <div style={{display: 'flex', flexDirection: 'column'}}>
              {updatingAddress ? 
                <div>
                  <form onSubmit={handleAddressSubmit}>
                    <div style={{marginBottom: 20} }>Billing Address</div>
                    <div className='jobLocationField'>
                        <div>Address Line 1</div>
                        <input
                            placeholder="Address Line 1"
                            type="text"
                            name='line1'
                            value={addressInputs.address.line1}
                            onChange={handleAddressChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>Address Line 2</div>
                        <input
                            placeholder="Address Line 2"
                            type="text"
                            name='line2'
                            value={addressInputs.address.line2}
                            onChange={handleAddressChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>City</div>
                        <input
                            placeholder="City"
                            type="text"
                            name='city'
                            value={addressInputs.address.city}
                            onChange={handleAddressChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>State</div>
                        <input
                            placeholder="State"
                            type="text"
                            name='state'
                            value={addressInputs.address.state}
                            onChange={handleAddressChange}
                        />
                    </div>
                    <div className='jobLocationField'>
                        <div>Zip Code</div>
                        <input
                            placeholder="Zip Code"
                            type="text"
                            name='zip'
                            value={addressInputs.address.zip}
                            onChange={handleAddressChange}
                        />
                    </div>
                    <button>Submit Address</button>
                  </form>
                </div>
              :
                ''
              }
            </div>
            :
              ''
            }
            <div style={{display:'flex', flexDirection:'column'}}>
              <button className='logoutBtn' onClick = {toggleUpdatingAddress}>Update Address</button>
              <button style={{marginTop: updatingAddress ? 100 : 10}} className='logoutBtn' onClick = {logout}>Log Out</button>
            </div>
            <div>
              {user.access === 'member' ? 
                <button className='openRequestBtn' onClick={toggleRequestDisplayed}>
                    Request Work
                </button>  : ''}
            </div>
            <div>
                {user.access === 'member' ? 
                  <button onClick={toggleMyRequestsDisplayed} className ='viewRequestBtn'>
                    
                      View My Requests
                    
                  </button>: ''
                }
              </div>
            
            <div>
              {user.access === 'admin' || 'field tech' ? <button className = 'requestBtn' onClick={toggleSearch}>Manage Jobs</button> : ''}
            </div>
            <div>
              {user.access === 'admin' ? <button className = 'requestBtn'onClick={toggleUserManagement}>Manage Users</button> : ''}
            </div>
            <div>
              {user.access === 'admin' ? <button className = 'requestBtn' onClick={toggleRequested}>Reports</button> : ''}
            </div>
            <div>
              {user.access === 'admin' || 'field tech' ? <button className = 'requestBtn'onClick={toggleScheduleDisplay}>Open Scheduler</button> : ''}
            </div>
            
            </div>
            <div className='accountInfoView'>
              {scheduleOpen ? <Scheduler myEvents = {displayedEvents} closeModal = {toggleScheduleDisplay}/> : ''}
              <div>
                <Report show={isRequested} closeModal = {toggleRequested}/>
              </div>
              <JobSearch show = {searchOpen} closeModal = {toggleSearch}/>
              <UserManager show = {userManagement} closeModal = {toggleUserManagement}/>
              {requestDisplayed ? <WorkRequest toggleRequestDisplayed = {toggleRequestDisplayed}/> : ''}
              
              {myRequestsDisplayed ? <AccountRequestsView jobs = {userRequests}/> : ''}
            </div>
          </div>
    )
  }else if(!user.isVerified && !user.tempRequested){
    return(
      <div>
        <EmailVerification/>
      </div>
      
    )
  }else if(user.tempRequested){
    return(
      <div>
        <PasswordChangeForm/>
      </div>
    )
  }
}
