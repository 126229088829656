import React, {useState, useRef} from "react";
import {Link} from 'react-router-dom';


export default function Menu(props){

    const [toggle, setToggle] = useState(false)
    const [childIsFocused, setChildIsFocused] = useState(false)

    const ref = useRef(null)

    function Toggle(){
        setToggle( prevToggle => !prevToggle )
        if(!toggle){
            setTimeout(() => ref.current.focus(), 30)
        }
    }

    function toggleToFalse(){
        setTimeout(() => {
            if(!childIsFocused){
                setToggle( prevToggle => prevToggle = false )
            }
        }, 200)  
    }

    function setChildFocusTrue(){
        setChildIsFocused( prevState => prevState = true)
    }

    function setChildFocusFalse(){
        setChildIsFocused( prevState => prevState = false)
    }

    return(

        <div>
            <div onClick = {Toggle} className="mobileButton">
                <div className="burgerBar"></div>
                <div className="burgerBar"></div>
                <div className="burgerBar"></div>
            </div>
            <div ref = {ref} onBlur={toggleToFalse} tabIndex = '1' className={toggle ? props.className2 : props.className}>
            
                <Link to = '/' style={{textDecoration:'none'}}>
                    <div onClick={setChildFocusTrue} tabIndex = '1' onBlur={setChildFocusFalse} className={toggle ? 'mobileMenuItem' : 'menuItem'}>
                        Home
                    </div>
                </Link>
                <Link to = '/contact' style={{textDecoration:'none'}}>
                    <div onClick={setChildFocusTrue} tabIndex = '1' onBlur={setChildFocusFalse} className={toggle ? 'mobileMenuItem' : 'menuItem'}>
                        Contact
                    </div>
                </Link>
                <Link to = '/about' style={{textDecoration:'none'}}>
                    <div onClick={setChildFocusTrue} tabIndex = '1' onBlur={setChildFocusFalse} className={toggle ? 'mobileMenuItem' : 'menuItem'}>
                        About Us
                    </div>
                </Link>
                <Link to = '/login' style={{textDecoration:'none'}}>
                    <div onClick={setChildFocusTrue} tabIndex = '1' onBlur={setChildFocusFalse} className={toggle ? 'mobileMenuItem' : 'menuItem'}>
                        {props.token ? 'My Account' : 'Login/Signup'}
                    </div>
                </Link>
            </div>
        </div>
    )
}