import React, {useState, Children} from "react";
import ReactDOM from 'react-dom'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import Toolbar from "react-big-calendar/lib/Toolbar";
import SchedulerJob from "./SchedulerJob";

//import NewCalendar from "./NewCalendar";
//import TestGrid from "./TestGrid";
//import DatePicker from 'react-datepicker'



// const events = [
//     {
//         title: 'some title',
//         start: new Date(2022,8,29),
//         end: new Date(2022,9,31)
//     },
//     {
//         title: 'other title',
//         start: new Date(2022,7,29),
//         end: new Date(2022,8,31),
//     }

// ]
    
export default function Scheduler(props){
  const {closeModal, myEvents} = props
    // const [selectedEvent, setSelectedEvent] = useState(undefined)
    // const [modalState, setModalState] = useState(false)

    // const handleSelectedEvent = (event) => {
    //   setSelectedEvent(event)
    //   setModalState(true)
    // }



    // const Modal = () => {
    //   return (
    //      <div className={`modal-${modalState === true ? 'show' : 'hide'}`}>
    //         {/* // Here you define your modal, what you want it to contain. 
    //         // Event title for example will be accessible via 'selectedEvent.title' */}
    //         {selectedEvent.title}
    //      </div>
    //   )
    // }

    const [showJob, setShowJob] = useState(false)
    const [focusedRef, setFocusedRef] = useState('')
    const [tileSize, setTileSize] = useState(10)

    function handleTileSizeChange(e){
      const { value } = e.target
      setTileSize(Number(value))
    }

    function openDetails(e){
      setShowJob(prevState => !prevState)
      setFocusedRef( prevState => prevState = e._id)
    }

    function closeDetails(){
      setShowJob(prevState => !prevState)
    }

    function eventStyleGetter(event, start, end, isSelected) {
      //event is coming from displayed events on Account.js
      var backgroundColor = ''
        if(event.isForInspection){
          backgroundColor = 'red'
        }else{
          backgroundColor = 'blue'
        }
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 1,
            color: 'white',
            border: '0px',
            display: 'block',
            padding: 0,
            fontSize: tileSize
        };
        return {
            style: style
        };
    }

    const locales = {
      'en-US': require("date-fns/locale/en-US")
    }

    const localizer = dateFnsLocalizer({
      format,
      parse,
      startOfWeek,
      getDay,
      locales
  })

  const currentDay = new Date().toDateString()

  const ColoredDateCellWrapper = ({children, value}) =>
    React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            backgroundColor: (value.toDateString() === currentDay ? 'antiquewhite' : 'black')
        },
    });

    return ReactDOM.createPortal(
        <div className="calendarContainer">
          <div style={{display: 'flex'}}>
          <div style={{marginRight: 5}}>Event Tile Size:</div>
            <div>
              <input
                style={{width:'38px'}}
                type="number"
                onChange={handleTileSizeChange}
                name='tileSize'
                value={tileSize}
              />
            </div>
            <h2 className='cyanExitButton' onClick={closeModal} style={{marginLeft: 'auto'}}>X</h2>
          </div>
            <Calendar
                localizer={localizer}
                events={myEvents} 
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventStyleGetter}
                components={{
                    dateCellWrapper: ColoredDateCellWrapper,
                    toolbar: ({ localizer, ...props }, children) => (
                      <Toolbar
                        {...props}
                        localizer={{
                          label: localizer.label,
                          messages: {
                            ...localizer.messages,
                            month: <div style={{color: 'white'}}>Month</div>,
                            week: <div style={{color: 'white'}}>Week</div>,
                            today: <div style={{color: 'white'}}>Today</div>,
                            next: <div style={{color: 'white'}}>Next</div>,
                            previous: <div style={{color: 'white'}}>Back</div>,
                            day: <div style={{color: 'white'}}>Day</div>,
                            agenda: <div style={{color: 'white'}}>Agenda</div>
                          }
                        }}
                      >
                        {children}
                      </Toolbar>
                    ),
                  }}
                  //onSelectSlot={(e) => handleSelect(e)}
                  onSelectEvent={(e) => openDetails(e)}
            />
            {showJob ? <SchedulerJob jobID = {focusedRef} close = {closeDetails} /> : ''}

            {/* <NewCalendar/> */}
            {/* <TestGrid/> */}
        </div>,
        document.getElementById("portal")
    )
}
//// sample code found on jquese github. Must be moved to be functional
// let defaultMessages = {
//     date: 'Date',
//     time: 'Time',
//     event: 'Event',
//     allDay: 'All Day',
//     week: 'Week',
//     work_week: 'Work Week',
//     day: 'Day',
//     month: 'Month',
//     previous: 'Back',
//     next: 'Next',
//     yesterday: 'Yesterday',
//     tomorrow: 'Tomorrow',
//     today: 'Today',
//     agenda: 'Agenda',
  
//     noEventsInRange: 'There are no events in this range.',
  
//     showMore: total => `+${total} more`,
//   }