import React, {useState, useContext, useEffect} from "react";
import {UserContext} from "../context/UserProvider";
import axios from "axios";

export default function UserCreatedUser(props){

    const {toggleCreatingUserOff} = props

    const {
        transProtocol,
        connectServerString,
        handleAuthErr,
        handleResMsg,
        loadingOn
    } = useContext(UserContext)

    const initInputs = {
        email:'',
        password:'',
        firstName:'',
        lastName:'',
        address:{
            line1:'',
            line2:'',
            city:'',
            state:'',
            zip:''
        }
    }

    const [inputs, setInputs] = useState(initInputs)

    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    function createAUser(credentials){
        loadingOn()
        userAxios.post(`${transProtocol}://${connectServerString}/api/access/createuser`, credentials)
        .then((res => {
            handleResMsg(res.data)
        }))
        .catch(err => {
            handleAuthErr(err.response.data.errMsg)
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    window.document.body.style.zoom = 1;

    function handleChange(e) {
        const { name, value } = e.target;
              
        if(name === 'firstName' || name ==='lastName' || name ==='email' || name ==='password'){
                setInputs((prevInputs) => (
                    { ...prevInputs, [name]: value}
                ));
        }else if(name === 'line1'||name ==='line2'||name ==='city'||name ==='state'||name ==='zip'){
                setInputs((prevInputs) => (    
                    {...prevInputs, address:{...prevInputs.address, [name]: value}}
                ));
        }
    }

      function handleSubmit(e) {
        e.preventDefault();
        toggleCreatingUserOff()
        createAUser(inputs)
      }

    return(
        <div className="usercreateduser">
            <form onSubmit={handleSubmit} className = 'accountCreateForm'>
                <div>
                    <div>First Name</div>
                    <input
                        placeholder="First Name"
                        autoFocus
                        type="text"
                        name='firstName'
                        value={inputs.firstName}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Last Name</div>
                    <input
                        placeholder="Last Name"
                        type="text"
                        name='lastName'
                        value={inputs.lastName}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Address Line 1</div>
                    <input
                        placeholder="Address Line 1"
                        type="text"
                        name='line1'
                        value={inputs.address.line1}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Address Line 2</div>
                    <input
                        placeholder="Address Line 2"
                        type="text"
                        name='line2'
                        value={inputs.address.line2}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>City</div>
                    <input
                        placeholder="City"
                        type="text"
                        name='city'
                        value={inputs.address.city}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>State</div>
                    <input
                        placeholder="State"
                        type="text"
                        name='state'
                        value={inputs.address.state}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Zip Code</div>
                    <input
                        placeholder="Zip Code"
                        type="text"
                        name='zip'
                        value={inputs.address.zip}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>Email Address</div>
                    <input
                        placeholder="Email Address"
                        type="text"
                        name='email'
                        value={inputs.email}
                        onChange={handleChange}
                    />
                </div>
                <button>Submit</button>
            </form>
        </div>
    )
}