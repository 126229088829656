import React,{useState,useContext, useEffect} from "react";
import AdminJob from "./AdminJob";
import { UserContext } from "../context/UserProvider";
import ReactDOM from "react-dom";
import axios from "axios";
import ViewNotes from "./ViewNotes";

export default function JobsOfUserByAdmin(props){

    const {closeModal, user} = props
    
    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    const { 
        connectServerString,
        transProtocol,
        loadingOn,
        loadingOff,
        handleAuthErr
    } = useContext(UserContext)
    
    useEffect(()=>{
        loadingOn()
        userAxios.get(`${transProtocol}://${connectServerString}/api/access/userworkbyadmin/${user._id}`)
            .then(res => {
                setSearchResults(prevState => (
                    [
                        ...res.data
                    ]
                ))
                loadingOff()
            })
            .catch(err => handleAuthErr(err.response.data.errMsg))
            // eslint-disable-next-line
    },[])

    const [searchResults, setSearchResults] = useState([])

    const [showNotes, setShowNotes] = useState(false)

    const [recall, setRecall] = useState(false)

    const [refID, setRefID] = useState('')

    function updateRefID(newRefID){
        setRefID(newRefID)
    }

    function toggleRecall(){
        setRecall(prevState => !prevState)
    }

    function toggleNotes(){
        setShowNotes(prevState => !prevState)
    }
    function toggleNotesOn(){
        setShowNotes(true)
    }

    const results = searchResults.map(job => (
        <AdminJob
            key = {job._id} 
            request = {job}
            updateRef = {updateRefID} 
            toggleNotes = {toggleNotesOn}
            toggleRecall = {toggleRecall}
        />
    ))
    return ReactDOM.createPortal(
        <div className="jobSearchContainer">
            <h2 onClick={closeModal} className = 'cyanExitButton'>X</h2>
            {/* <div className="printResults">
                <div>Print Results</div>
                <img src = {require ('../imgs/printerIcon.png')} alt = '' className="printerIcon"></img>
            </div> */}
            <div>Jobs for {user.firstName} {user.lastName}</div>
            <div className="resultsContainer">
                {
                    results.length !==0 ? 
                        results
                        : 
                        <div>No Results Found</div>
                }
            </div>
            <ViewNotes show = {showNotes} toggleNotes = {toggleNotes} refID = {refID} reset = {recall}/>
        </div>,
        document.getElementById("jobsPortal")
    )
}