import React, {useState, useContext, useEffect} from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import { UserContext } from "../context/UserProvider";
import AdminUserView from "./AdminUserView";
import UserCreatedUser from "./UserCreatedUser";
import ViewNotes from "./ViewNotes";


export default function UserManager(props){

    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    const { 
        connectServerString,
        transProtocol,
        loadingOn,
        loadingOff,
        handleAuthErr
    } = useContext(UserContext)

    const [sortOptions, setSortOptions] = useState({
        sortBy: 'memberSince',
        order: false
    })

    function handleSortChange(e){
        const {name, value} = e.target
        setSortOptions((prevState) => ({...prevState, [name]: value}))
    }

    function toggleOrder(){
        setSortOptions(prevState => ({...prevState, order: !prevState.order}))
    }

    useEffect(()=>{
        setSearchResults(prevState => {
            return [...prevState.sort((a, b) => {
                    if(sortOptions.sortBy === 'contactLastName'||sortOptions.sortBy === 'contactEmail'){
                        //currently unused
                        return(
                            (a.poc[sortOptions.sortBy] > b.poc[sortOptions.sortBy]) 
                            ? sortOptions.order ? -1 : 1
                            : (a.poc[sortOptions.sortBy] < b.poc[sortOptions.sortBy]) 
                            ? sortOptions.order ? 1 : -1 
                            : 0
                        )
                    }else{
                        let nameA = a[sortOptions.sortBy].toUpperCase()
                        let nameB = b[sortOptions.sortBy].toUpperCase()
                        return(
                            (nameA > nameB) 
                            ? sortOptions.order ? -1 : 1 
                            : (nameA < nameB) 
                            ? sortOptions.order ? 1 : -1  
                            : 0
                        )
                    }
            })]
        })
    },[sortOptions])

    const [searchResults, setSearchResults] = useState([])
    const [searchType, setSearchType] = useState({
        searchTypeValue:'address'
    })
    const [addressSearchLine, setAddressSearchLine] = useState({
        addressLine:'line1'
    })
    const [searchParam, setSearchParam] = useState({
        searchValue:''
    })
    const [creatingUser, setCreatingUser] = useState(false)
    
    const [showNotes, setShowNotes] = useState(false)

    const [recall, setRecall] =useState(false)

    const [refID, setRefID] = useState('')

    function updateRefID(newRefID){
        setRefID(newRefID)
    }
    function toggleRecall(){
        setRecall(prevState => !prevState)
    }
    function toggleNotes(){
        setShowNotes(prevState => !prevState)
    }
    function toggleNotesOn(){
        setShowNotes(true)
    }

    function toggleCreatingUserOn(){
        setCreatingUser(true)
    }
    function toggleCreatingUserOff(){
        setCreatingUser(false)
    }

    function handleSubmit(e){
        e.preventDefault()
        loadingOn()
        toggleCreatingUserOff()
        if(searchType.searchTypeValue === 'name'){
            const strArr = searchParam.searchValue.split(" ")
            const filteredArr = strArr.filter((i)=> i !== '')
            const firstName = '^' + filteredArr[0]
            let lastName = ''
            if(filteredArr[1]){
                lastName = lastName + '^' + filteredArr[1]
                userAxios.get(`${transProtocol}://${connectServerString}/api/access/userslist/searchByName/${firstName}/${lastName}`)
                    .then(res => {
                        if(res.status ===200){
                            setSearchResults(prevSearchResults => (
                                [
                                    ...res.data
                                ]))
                            loadingOff()
                        }else{
                        setSearchResults([])
                        loadingOff()
                        }
                    })
                    .catch(err => {
                        handleAuthErr(err.message)
                        setSearchResults([])
                    })
            }else{
                userAxios.get(`${transProtocol}://${connectServerString}/api/access/userslist/searchByLastName/${firstName}`)
                    .then(res => {
                        if(res.status ===200){
                            setSearchResults(prevSearchResults => (
                                [
                                    ...res.data
                                ]))
                            loadingOff()
                        }else{
                        setSearchResults([])
                        loadingOff()
                        }
                    })
                    .catch(err => {
                        handleAuthErr(err.message)
                        setSearchResults([])
                    })
            }
        }else if(searchType.searchTypeValue === 'address'){
            userAxios.get(`${transProtocol}://${connectServerString}/api/access/usersList/search/${searchType.searchTypeValue}/${searchParam.searchValue}/${addressSearchLine.addressLine}`)
            .then(res => {
                if(res.status ===200){
                    setSearchResults(prevSearchResults => (
                        [
                            ...res.data
                        ]))
                    loadingOff()
                }else{
                   setSearchResults([])
                   loadingOff()
                }
            })
            .catch(err => {
                handleAuthErr(err.message)
                setSearchResults([])
            })
        }else{
            userAxios.get(`${transProtocol}://${connectServerString}/api/access/usersList/search/${searchType.searchTypeValue}/${searchParam.searchValue}`)
            .then(res => {
                if(res.status ===200){
                    setSearchResults(prevSearchResults => (
                        [
                            ...res.data
                        ]))
                    loadingOff()
                }else{
                   setSearchResults([])
                   loadingOff()
                }
            })
            .catch(err => {
                handleAuthErr(err.message)
                setSearchResults([])
            })
        }
    }

    function handleChange(e){
        const {name, value} = e.target
        setSearchParam((prevState) => ({...prevState, [name]: value}))
    }
    function handleTypeChange(e){
        const {name, value} = e.target
        setSearchType((prevState) => ({...prevState, [name]: value}))
    }
    function handleAddressSearchLineChange(e){
        const {name, value} = e.target
        setAddressSearchLine((prevState) => ({...prevState, [name]: value}))
    }

    // const printResults = () =>{     
    //     //console.log('print');
         
    //     let printContents = document.getElementsByClassName('resultsContainer');
    //     console.log('reached')
    //     let blankDiv = document.getElementById('divToPrint').contentWindow
    //     blankDiv.open()
    //     blankDiv.document.write(printContents.innerHTML);
    //     blankDiv.focus();
    //     blankDiv.print();
         
    //   }

    const results = searchResults.map(userView => (
        <AdminUserView 
            key = {userView._id} 
            userView = {userView}
            updateRef = {updateRefID} 
            toggleNotes = {toggleNotesOn}
            toggleRecall = {toggleRecall}
        />
    ))

    function resultsContainerView(){
        if(creatingUser){
            return(<UserCreatedUser toggleCreatingUserOff={toggleCreatingUserOff}/>)
        }else{
            if(results.length !== 0){
                return(results)
            }else{
                return(<div>No Results Found</div>)
            }
        }
    }

    if(!props.show)return null
    return ReactDOM.createPortal(
        <div className="jobSearchContainer">
            <h2 onClick={props.closeModal} className = 'cyanExitButton'>X</h2>
            {/* <div className="printResults">
                <div>Print Results</div>
                <img src = {require ('../imgs/printerIcon.png')} alt = '' className="printerIcon"></img>
            </div> */}
            <div className="jobSearchHead">
            <div className="createUserBtn" onClick={toggleCreatingUserOn}>Create New User</div>
                <form onSubmit={handleSubmit} className = 'searchForm'>
                    <span style={{marginRight: 5}}>Find By:</span>
                    <select className="searchTypeSelect" 
                        name='searchTypeValue' 
                        onChange={handleTypeChange}
                        style={{marginRight: 5}}
                    >
                        <option value={'address'}>Address</option>
                        <option value={'name'}>Name</option>
                        <option value={'email'}>E-mail</option>
                    </select>
                    
                    <div style={{marginTop: 10}}>
                        <div style={{marginBottom: 10, display: searchType.searchTypeValue === 'address' ? 'block' : 'none'}}>Address Line: 
                            <select 
                                style={{marginLeft: 5}}
                                onChange={handleAddressSearchLineChange}
                                value={addressSearchLine.addressLine}
                                name='addressLine'
                            >
                                <option value = {`line1`}>Street</option>
                                <option value = {`city`}>City</option>
                                <option value = {`state`}>State</option>
                                <option value = {`zip`}>Zip</option>
                            </select>
                        </div>
                        <input
                            onChange={handleChange}
                            name='searchValue'
                            type="text"
                            placeholder="Search"
                            autoFocus={true}
                        />
                    <button style={{marginLeft: 5}}>Search</button>
                    </div>
                </form>
                <div style={{marginLeft: 5}}>Sort By: 
                    <select
                        name = 'sortBy' 
                        onChange={handleSortChange}
                        style={{marginRight: 5, marginLeft: 5}}
                        value = {sortOptions.sortBy}
                    >
                        <option value={'memberSince'}>Date Created</option>
                        <option value={'lastName'}>Last Name</option>
                        <option value={'email'}>Email</option>
                    </select>
                </div>
                <div onClick={toggleOrder} style={{cursor:'pointer'}}>
                    <img style={{marginRight: 5, cursor:'pointer'}} src = {require ('../imgs/ascending-sorting.png')} alt = '' className="orderIcon"></img>
                    {sortOptions.order ? 'Descending' : 'Ascending'}
                </div>
            </div>
            <div>{results.length} Results Found</div> 
            
            <div className="resultsContainer">
                {resultsContainerView()}
            </div>
            <ViewNotes show = {showNotes} toggleNotes = {toggleNotes} refID = {refID} reset = {recall}/>
        </div>,
        document.getElementById("userManager")
    )
}