import React from "react";

export default function Home(){
    return(
        <div className='home'>
            <h1 style={{marginLeft: -20}}>Welcome to Earl E. Preston Jr., Inc.</h1>
            <img src = {require ('./imgs/septicTruck.jpg')} alt = '' className="homeImage"></img>
            <h2 style={{color: 'cyan'}}>{`Complete Commercial & Residential Septic Service`}</h2>
            <h3>{`Conventional Septic Systems & Alternative Septic Systems`}</h3>
            <h4>Sand mounds, Low-pressure Dose, Drip Irrigation, Pre-treatment Units, Tank Upgrades, Holding Tanks, Drywells, Grease Traps</h4>
            <h4>Installation, Maintenance, Pumping, Repair, Inspection / Certification, Percolation Testing</h4>
            <h2 style={{color: 'cyan'}}>{`Commercial & Residential Excavation`}</h2>
            <h4>Grading, Storm Drains, Foundations, Driveways, Well Lines, Roads, Ponds, Perc Tests</h4>
            <div style={{marginTop:50, marginBottom:50}}>
                <h2 style={{color:'cyan'}}>Located in Maryland's</h2>
                <h2 style={{color:'cyan'}}>Beautiful Harford County</h2>
                <h4>Let Earl E. Preston Jr. Inc., one of Harford & Baltimore</h4>
                <h4>Counties' oldest, well-respected septic contractors help you</h4>
                <h4>complete your residential or commercial project.</h4>
            </div>
            <h2 style={{marginTop: 50, color: 'cyan'}}>Super Service Awards History</h2>
            <h3>Year / Category</h3>
            <h4>2015 Septic Tank</h4>
            <h4>2015 Excavating</h4>
            <h4>2014 Septic Tank</h4>
            <h4>2014 Excavating</h4>
            <h4>2013 Septic Tank</h4>
            <h4>2013 Excavating</h4>
            <h4>2011 Septic Tank</h4>
            <h4 style={{marginBottom: 100}}>2007 Septic Tank</h4>
        </div>
    )
}