import React, {useState, useContext, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import Note from "./Note";
import { UserContext } from "../context/UserProvider";
import axios from "axios";

export default function ViewNotes(props){
    const { show, toggleNotes, refID, reset } = props

    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    const { 
        connectServerString,
        transProtocol
    } = useContext(UserContext)

    const textValueRef = useRef();

    const [addingNote, setAddingNote] = useState(false)
    const [addedNote, setAddedNote] = useState('')
    const [notesArr, setNotesArr] = useState([])

    useEffect(()=>{
        if(show){
            userAxios.get(`${transProtocol}://${connectServerString}/api/access/notes/${refID}/${0}`)
                .then(res => {
                    setNotesArr([])
                    setNotesArr(prevState => (
                        [
                            ...res.data
                        ]
                    ))
                })
                .catch(err => console.log(err))
        }
    },[refID, reset])// eslint-disable-line

    function loadMoreData(){
        userAxios.get(`${transProtocol}://${connectServerString}/api/access/notes/${refID}/${notesArr.length}`)
            .then(res => {
                setNotesArr(prevState => (
                    [
                        ...prevState,...res.data
                    ]
                ))
            })
            .catch(err => console.log(err))
    }
    
    function toggleAddingNote(){
        setAddingNote(prevState => !prevState)
        setAddedNote('')
    }
    function handleChange(e){
        const {name, value} = e.target
        setAddedNote((prevState) => ({...prevState, [name]: value}))
    }

    function postNote(note){
        userAxios.post(`${transProtocol}://${connectServerString}/api/access/notes/addnote/${refID}`, note)
        .then((res => {
            setNotesArr(prevState => (
                [
                    res.data, ...prevState
                ]
            ))
            setAddingNote(prevState => prevState = false)
        }))
        .catch(err => console.log(err))
    }

    function handleSubmit(e){
        e.preventDefault()
        
        postNote(addedNote)
    }

    const Notes = notesArr.map((note, i)=>{
        return(
            <Note note = {note} key = {note._id} i = {i+1}/>
        )
    })
    if(!show)return null
    return ReactDOM.createPortal(
        <div className="notesWindow">
            <div className="notesHeader">
                <div className="addNoteBtn" onClick={toggleAddingNote}>{addingNote ? 'Close': 'Add'} Note</div>
                <div className="blackExitButton" onClick={toggleNotes}>X</div>
            </div>
            <div className="notesArr">
                {Notes}
                <div className="loadMoreBtn" onClick={loadMoreData}>Load More...</div>
            </div>
            {addingNote ?
                <div className="addNoteWindow" >
                    <form style={{height: '80%', width: '100%'}} onSubmit={handleSubmit}>
                        <div style={{fontSize: 13}}>Room For: {addedNote.length!==0 ? 300 - textValueRef.current?.value.length : 300} characters</div>
                        <textarea 
                            ref={textValueRef}
                            style={{height: '100%', width: '100%'}}
                            maxLength = '300'
                            name="addedNote"
                            onChange={handleChange}
                        >
                        </textarea>
                        <button>Post Note</button>
                    </form>
                </div>
            :
            ''}
        </div>,
        document.getElementById("notesView")
    )
}