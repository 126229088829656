import React, {useState, useContext, useEffect} from "react";
import ReactDOM from "react-dom";
import { UserContext } from "../../context/UserProvider";
import ViewNotes from "../ViewNotes";
import axios from "axios";

export default function JobLOM(props){
    const {show, refID, toggleLOM, address} = props

    const { 
        connectServerString,
        transProtocol
    } = useContext(UserContext)

    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    const [showInputs, setShowInputs] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showNotes, setShowNotes] = useState(false)
    const [recall, setRecall] = useState(false)
    const [lomID, setLomID] = useState('')
    const [listArr, setListArr] = useState([])

    function setShowConfirmTrue(){
        setShowConfirm(prevState => true)
    }

    function setShowConfirmFalse(){
        setShowConfirm(prevState => false)
    }

    function toggleRecall(){
        setRecall(prevState => !prevState)
    }

    function toggleNotes(){
        setShowNotes(prevState => !prevState)
    }
    function toggleNotesOn(){
        setShowNotes(true)
    }

    const [inputs, setInputs] = useState({
        material: '',
        matCount: 0,
        matUnit: ''
    })

    const [itemToDelete, setItemToDelete] = useState({
        material: '',
        matCount: 0,
        matUnit: ''
    })

    function toggleInputs(){
        setShowInputs(prevState => !prevState)
    }

    function getLomID(id){
        setLomID(id)
    }

    function updateRefAndToggle(){
        getLomID(lomID)
        toggleNotesOn()
        toggleRecall()
    }

    function transferItemDataToConfirmation(index){
        setItemToDelete( prevState => (
            {...listArr[index], refI: index}
        ))
        setShowConfirmTrue()
    }

    function handleChange(e) {
        const { name, value } = e.target;
            setInputs((prevInputs) => (
                { ...prevInputs, [name]: value}
            ));
    }

    // function updateRefAndToggle(){
    //     updateRef(request._id)
    //     toggleNotes()
    //     toggleRecall()
    // }

    function deleteFromList(thisIndex){
        userAxios.delete(`${transProtocol}://${connectServerString}/api/access/deletefromlom/${refID}/${listArr.indexOf(listArr[thisIndex])}`)
            .then(res => {
                setShowConfirmFalse()
                if(res.data === 'Successfully Deleted'){
                    setListArr(prevState => (
                        [
                            ...listArr.filter(item => 
                                listArr.indexOf(item) !== listArr.indexOf(listArr[thisIndex])
                            )
                        ]
                    ))
                }

            })
            .catch(err => console.log(err))
    }

    function addToList(lineItem){
        lineItem.material.trim().replace(/  +/g, ' ')
        lineItem.matCount.trim().replace(/  +/g, ' ')
        lineItem.matUnit.trim().replace(/  +/g, ' ')
        userAxios.post(`${transProtocol}://${connectServerString}/api/access/addtolom/${refID}`, lineItem)
            .then(res => {
                setListArr(prevState => (
                    [
                        ...prevState, res.data
                    ]
                ))
            })
            .catch(err => console.log(err))
    }

    function getList(refID){
        userAxios.get(`${transProtocol}://${connectServerString}/api/access/getlom/${refID}`)
            .then(res => {
                setListArr([])
                if(res.data[0].list){
                    setListArr(prevState => ([...res.data[0].list]))
                    getLomID(res.data[0]._id)
                }
            })
            .catch(err => console.log(err))
    }

    function handleSubmit(e){
        e.preventDefault()
        addToList(inputs)
    }

    useEffect(()=>{
        if(show){
            getList(refID)
        }
    },[refID])// eslint-disable-line

    const listDisplay = listArr.map((lineItem, i)=>{
        return(
            <div className="lineItem" key={i}>
                <div className="col">
                    {lineItem.material}
                </div>
                <div className="col">
                    {lineItem.matCount}
                </div>
                <div className="col">
                    {lineItem.matUnit}
                </div>
                   
                <button style={{marginLeft: 'auto'}} onClick={() => transferItemDataToConfirmation(i)}>Delete</button>
            </div>
        )
    })

    if(!show)return null
    return ReactDOM.createPortal(
        <div className="lom">
            <h2 onClick={toggleLOM} className = 'cyanExitButton'>X</h2>
            <div style={{display: 'flex'}}>
                <div style={{marginRight: 15}}>For Job Location:</div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {address.line1}
                    </div>
                    {address.line2 ? 
                        <div>
                            {address.line2}
                        </div>
                        :
                        ''
                    }
                    <div>
                        {address.city}, {address.state} {address.zip}
                    </div>
                </div>
            </div>
            {showConfirm ? 
                <div className="confirmDeleteBacking">
                    <div className="confirmDelete">
                        <div style={{color: 'red'}}>Delete this item?</div>
                        <div>{itemToDelete.material} {itemToDelete.matCount} {itemToDelete.matUnit}</div>
                        <button style={{margin: 15}} onClick={() => deleteFromList(itemToDelete.refI)}>Confirm</button>   
                        <button onClick={setShowConfirmFalse}>Cancel</button>
                    </div> 
                </div>
                :
                ''
            }
            
            <div className="lomContainer" style={{flexDirection: 'column'}}>
            <div className="lineItemHeader">
                <div className="colH">
                    Material
                </div>
                <div className="colH">
                    Count
                </div>
                <div className="colH">
                    Unit
                </div>
                <div style={{width: 55}}></div>
            </div>
                {
                    listDisplay.length !==0 ? 
                        listDisplay
                        : 
                        <div>No List Found</div>
                }
            </div>
            <div>
                <button style={{margin: 50}} onClick={toggleInputs}>Add Item</button>
                <button onClick={updateRefAndToggle}>View Notes</button>
            </div>
            
            <div style={{display: showInputs ? 'block' : 'none'}}>
                <form onSubmit={handleSubmit}>
                    <div>
                        <input
                            placeholder="Material"
                            type="text"
                            name="material"
                            value={inputs.material}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            placeholder="Count"
                            type="number"
                            name="matCount"
                            value={inputs.matCount}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            placeholder="Unit"
                            type="text"
                            name="matUnit"
                            value={inputs.matUnit}
                            onChange={handleChange}
                        />
                    </div>
                    <button style={{marginBottom: 50}} onClick={toggleInputs}>Submit</button>
                </form>
            </div>
            <ViewNotes show = {showNotes} toggleNotes = {toggleNotes} refID = {lomID} reset = {recall}/>
        </div>,
        document.getElementById("lomPortal")
    )
}