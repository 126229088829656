import React, {useContext, useState} from "react";
import ReactDOM from "react-dom";
import { UserContext } from "../context/UserProvider";
import AssignedMemberEdit from "./AssignedMemberEdit";
import axios from "axios";

export default function AssignedList(props){
    const {show, toggleAssignedList, assignedList, address, jobID} = props

    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    const {
        memberList,
        user,
        transProtocol,
        connectServerString
    } = useContext(UserContext)

    const [showMemberList, setShowMemberList] = useState(false)
    const [assignedListRendered, setAssignedListRendered] = useState([...assignedList])
    const [fieldTechList, setFieldTechList] = useState(
        [...memberList.filter(member => 
            !assignedListRendered.find(i => 
                i.fieldTechID === member._id
            )
        )]
    )
    function toggleMembers(){
        setShowMemberList(prevState => !prevState)
    }

    function assignMember(userID){
        userAxios.post(`${transProtocol}://${connectServerString}/api/access/membersList/assignMember/${jobID}/${userID}`)
        .then((res => {
            setAssignedListRendered(prevState => (
                [...res.data]
            ))
            setFieldTechList(prevState => (
                [...fieldTechList.filter(person => person._id !== userID)]
            ))
        }))
        .catch(err => console.log(err))
    }
    function unassignMember(userID){
        userAxios.post(`${transProtocol}://${connectServerString}/api/access/membersList/unassignMember/${jobID}/${userID}`)
        .then((res => {
            setAssignedListRendered(prevState => (
                [...res.data]
            ))
            setFieldTechList(prevState => (
                [...memberList.filter(member => 
                    !res.data.find(i => 
                        i.fieldTechID === member._id
                    )
                )]
            ))
        }))
        .catch(err => console.log(err))
    }



    const members = fieldTechList.map((member, i) => {
        if(!assignedListRendered.some(person => 
            person.fieldTechID === member._id
        )){
            return(
                <AssignedMemberEdit 
                    member = {member} 
                    key = {i} assignMember = {() => assignMember(member._id)}
                />
            )
        }else{
            return('')
        }
    })

    const assignedMembers = assignedListRendered.map((member, i)=>{
        return(
            <div key = {i}>{member.fieldTechName} <button onClick={() => unassignMember(member.fieldTechID)}>Unassign</button></div>
        )
    })
    
    if(!show)return null
    return ReactDOM.createPortal(
        <div className="assignmentList">
            <h2 onClick={toggleAssignedList} className = 'cyanExitButton'>X</h2>
            <div style={{display: 'flex'}}>
                <div>
                    Members assigned to: 
                </div>
                <div style={{marginLeft: 10}}>
                    <div>
                        {address.line1}
                    </div>
                    <div>
                        {address.line2}
                    </div>
                    <div>
                        {address.city}, {address.state} {address.zip}
                    </div>
                </div>
            </div>
            <div className="resultsContainer">
                <div>{assignedMembers[0] ? assignedMembers : 'No one is assigned yet'}</div>
            </div>
            <div>
                {user.access === 'admin' ? <button onClick={toggleMembers}>Edit Assignments</button> : ''}
            </div>
            <div>
                {showMemberList ? members : ''}
            </div>
        </div>,
        document.getElementById("assignmentPortal")
    )
}