import React from "react"

export default function Header(props){
    return(
            <div className={props.className}>
                <div>
                Earl Preston Jr.
                </div>
                <div>
                    {`Sewer & Septic`}
                </div>
                {/* <a className="fbLink" href="./imgs/excavator.jpg"><img src={require ('./imgs/excavator.jpg')} alt = '' className="fbIcon" ></img></a> */}
            </div>
    )
}