import React, {useState, useContext} from "react";
import { UserContext } from '../context/UserProvider'
import WorkRequestByAdmin from "./WorkRequestByAdmin";
import JobsOfUserByAdmin from "./JobsOfUserByAdmin"
import axios from "axios";

export default function AdminUserView(props){

    const {userView, updateRef, toggleNotes, toggleRecall} = props

    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    const{
        loadingOn,
        handleAuthErr,
        handleResMsg,
        connectServerString,
        transProtocol
    } = useContext(UserContext)

    const [submittingRequest, setSubmittingRequest] = useState(false)
    const [showJobs, setShowJobs] = useState(false)
    const [updatingAddress, setUpdatingAddress] = useState(false)
    const [updatingRole, setUpdatingRole] = useState(false)
    const [roleInputs, setRoleInputs] = useState({
        access: ''
    })
    const [addressInputs, setAddressInputs] = useState({
        address: {
          line1:'',
          line2:'',
          city:'',
          state:'',
          zip:''
        }
      })
    
    function toggleRequest(){
        setSubmittingRequest(prevState => (
            prevState = !prevState
        ))
    }
    function toggleJobs(){
        setShowJobs(prevState => (
            prevState = !prevState
        ))
    }

    function handleChange(e){
        const {name, value} = e.target
        setRoleInputs((prevInputs) => ({...prevInputs, [name]: value}))
        console.log(roleInputs)
    }

    function updateRefAndToggle(){
        updateRef(userView._id)
        toggleNotes()
        toggleRecall()
    }

    function toggleUpdatingAddress(){
        setUpdatingAddress(prevState => !prevState)
    }

    function toggleUpdatingRole(){
        setUpdatingRole(prevState => !prevState)
    }

    function handleAddressSubmit(e){
        e.preventDefault()
        updateAddress(addressInputs)
        toggleUpdatingAddress()
    }
    
    function handleAddressChange(e){
    const { name, value } = e.target;
        if(name === 'line1'||name ==='line2'||name ==='city'||name ==='state'||name ==='zip'){
            setAddressInputs((prevInputs) => (    
                {...prevInputs, address:{...prevInputs.address, [name]: value}}
            ));
        }
    }
    
    function updateAddress(newAddress){
        loadingOn()
        userAxios.put(`${transProtocol}://${connectServerString}/api/access/updateaddressbyadmin/${userView._id}`, newAddress)
        .then(res => {
            handleResMsg(res.data)
        })
        .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    function updateRole(){
        loadingOn()
        userAxios.put(`${transProtocol}://${connectServerString}/api/access/updaterole/${userView._id}`, roleInputs)
        .then(res => {
            handleResMsg(res.data)
        })
        .catch(err => handleAuthErr(err.response.data))
    }

    return(
        <div className="adminUserViewContainer">
            <div>
                Name: {userView.firstName} {userView.lastName}
            </div>
            <div>
                Email: {userView.email}
            </div>
            {userView.address.line1 ?
                <div>
                    Address:
                    <div style={{marginLeft: 20}}>
                        <div>{userView.address.line1}</div>
                        {userView.address.line2 ? <div>{userView.address.line2}</div> : ''}
                        <div>{userView.address.city}, {userView.address.state.toUpperCase()}</div>
                        <div>{userView.address.zip}</div>
                    </div>
                </div> 
            : 
            ''
            }
            {updatingAddress ? 
                        <div style={{marginLeft: 50}}>
                        <form onSubmit={handleAddressSubmit}>
                            <div className='jobLocationField'>
                                <div>Address Line 1</div>
                                <input
                                    placeholder="Address Line 1"
                                    type="text"
                                    name='line1'
                                    value={addressInputs.address.line1}
                                    onChange={handleAddressChange}
                                />
                            </div>
                            <div className='jobLocationField'>
                                <div>Address Line 2</div>
                                <input
                                    placeholder="Address Line 2"
                                    type="text"
                                    name='line2'
                                    value={addressInputs.address.line2}
                                    onChange={handleAddressChange}
                                />
                            </div>
                            <div className='jobLocationField'>
                                <div>City</div>
                                <input
                                    placeholder="City"
                                    type="text"
                                    name='city'
                                    value={addressInputs.address.city}
                                    onChange={handleAddressChange}
                                />
                            </div>
                            <div className='jobLocationField'>
                                <div>State</div>
                                <input
                                    placeholder="State"
                                    type="text"
                                    name='state'
                                    value={addressInputs.address.state}
                                    onChange={handleAddressChange}
                                />
                            </div>
                            <div className='jobLocationField'>
                                <div>Zip Code</div>
                                <input
                                    placeholder="Zip Code"
                                    type="text"
                                    name='zip'
                                    value={addressInputs.address.zip}
                                    onChange={handleAddressChange}
                                />
                            </div>
                            <button>Submit Address</button>
                        </form>
                        </div>
              :
                ''
              }
            <div>
                Access: {userView.access}
            </div>
            <div>
                Verified: {userView.isVerified ? 'True' : 'False'}
            </div>
            <div>
                Account Created On: {new Date(userView.memberSince).toLocaleString()}
            </div>
            <button onClick={toggleRequest} style={{width: 150}}>Create Job For User</button>
            <button onClick={toggleUpdatingAddress} style={{width: 150}}>Update Address</button>
            <button onClick={toggleJobs} style={{width: 150}}>View User's Jobs</button>
            <button onClick={toggleUpdatingRole} style={{width: 150}}>Update Role</button>
            {updatingRole ? 
                <div>
                    <select
                        name = 'access'
                        onChange = { handleChange }
                        value = {roleInputs.access}
                    >
                        <option value={''}>Select</option>
                        <option value={'member'}>Member</option>
                        <option value={'field tech'}>Field Tech</option>
                    </select>
                    <button onClick={updateRole}>Update</button>
                </div> 
                    : ''
            }
            <button onClick={updateRefAndToggle} style={{width: 150}}>Notes</button>
            {submittingRequest ? <WorkRequestByAdmin userView={userView} toggleRequest={toggleRequest}/> : ''}
            {showJobs ? <JobsOfUserByAdmin user={userView} closeModal={toggleJobs}/> : ''}
        </div>
    )
}