import React from "react";

export default function About(props){
    return(
        <div className={props.className}>
            <h1>About the Company</h1>
            <h3 style={{color: 'red'}}>{`Licensed & Insured: Baltimore and Harford County, Maryland Home Improvement Commission`}</h3>
            <h4>We are one of the oldest and most respected septic and excavation contractors in Baltimore and Harford Counties. Our team has a wealth of experience and knowledge.</h4>
            <h4>A NOWRA (National Wastewater Recycling Association) certified and alternative service provider, Earl "Gene" Preston has been in business since 1971 as a septic and excavation contractor. Also licensed to install conventional and alternative systems , Doug Dufour is our superintendent and has been working with Gene since 1982. Each of our key employees has at least a dozen years of experience in the field, and has been with Gene for five years or more.</h4>
            <h4>We have a full line of construction equipment available.</h4>
            <h4>We are your local distributor for Jet Inc. pre-treatment units and Monarch Products Co. top-seam concrete septic tanks.</h4>
            <h3>Our commitment is to provide reliable, quality service for you!</h3>
        </div>
    )
}