import React, {useState, useContext} from "react";
import { UserContext } from "../context/UserProvider";
import pdfImg from '../imgs/pdfImg.png'
import axios from "axios";
import AssignedList from "./AssignedList";

export default function AdminJob(props){

    const {request, updateRef, updateLOMRef, toggleNotes, toggleRecall, toggleLOM} = props

    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    const {
        connectServerString, 
        socket,
        transProtocol,
        statusArray,
        coloring,
        loadingOn,
        loadingOff,
        handleAuthErr
    } = useContext(UserContext)
    // console.log(request.estimatedStart)
    // console.log(estStartDate)
    const [imageRequested, setImageRequested] = useState(false)
    const [updatingStatus, setUpdatingStatus] = useState(false)
    const [updatingStartTime, setUpdatingStartTime] = useState(false)
    const [updatingFinishTime, setUpdatingFinishTime] = useState(false)
    const [settingInspectTime, setSettingInspectTime] = useState(false)
    const [updatingActualStartTime, setUpdatingActualStartTime] = useState(false)
    const [updatingActualFinishTime, setUpdatingActualFinishTime] = useState(false)
    const [updatingActualCost, setUpdatingActualCost] = useState(false)
    const [updatingEstCost, setUpdatingEstCost] = useState(false)
    const [updatingContactInfo, setUpdatingContactInfo] = useState(false)
    const [showAssignedList, setShowAssignedList] = useState(false)

    const [inputs, setInputs] = useState({
        status: request.status,
        jobTitle: request.jobTitle || ''
    })

    const [contactInfo, setContactInfo] = useState({
        poc:{
            contactFirstName: request.poc.contactFirstName,
            contactLastName: request.poc.contactLastName,
            contactPhone: request.poc.contactPhone,
            contactEmail: request.poc.contactEmail
        }
    })

    const [newJob, setNewJob] = useState([])

    const [inspectDate, setInspectDate] = useState({
        inspectionDate: request.inspectionDate
    })

    const [startDate, setStartDate] = useState({
        estimatedStart: request.estimatedStart
    })

    const [finishDate, setFinishDate] = useState({
        estimatedFinish: request.estimatedFinish
    })
    const [estCost, setEstCost] = useState({
        estimatedCost: request.estimatedCost
    })

    const [commencedActual, setCommencedActual] = useState({
        startActual: request.commencedActual
    })
    
    const [completedActual, setCompletedActual] = useState({
        completedActual: request.completedActual
    })
    const [actualCost, setActualCost] = useState({
        actualCost: request.actualCost
    })
    

    const dateRequested = new Date(request.requestDate).toLocaleString()

    function handleBrokenImage(e){
        e.target.src = pdfImg
    }
    
    function updateRefAndToggle(){
        updateRef(request._id)
        toggleNotes()
        toggleRecall()
    }

    function viewAssigned(){
        setShowAssignedList(prevState => !prevState)
    }

    function updateLOMRefAndToggle(){
        updateLOMRef(request._id, request.jobLocation)
        toggleLOM()
        toggleRecall()
    }

    function toggleAllInputsOff(){
        setUpdatingStatus(prevState => (
            prevState = false
        ))
        setUpdatingContactInfo(prevState => (
            prevState = false
        ))
        setImageRequested(prevState => (
                    prevState = false
        ))
        setSettingInspectTime(prevState => (
                    prevState = false
        ))
        setUpdatingStartTime(prevState => (
                    prevState = false
        ))
        setUpdatingFinishTime(prevState => (
                    prevState = false
        ))
        setUpdatingEstCost(prevState => (
                    prevState = false
        ))
        setUpdatingActualCost(prevState => (
                    prevState = false
        ))
        setUpdatingActualStartTime(prevState => (
                    prevState = false
        ))
        setUpdatingActualFinishTime(prevState => (
                    prevState = false
        ))
    }

    function ping(){
        socket.emit("eventTime_update")
    }

    function updateStatus(jobID, newStatus){
        loadingOn()
        userAxios.put(`${transProtocol}://${connectServerString}/api/access/jobstatus/${jobID}`, newStatus)
        .then((res => {
            setNewJob(prevState => ({...prevState, ...res.data}))
            if(
                Object.keys(newStatus)[0] === 'estimatedFinish'
                ||
                Object.keys(newStatus)[0] === 'estimatedStart'
                ||
                Object.keys(newStatus)[0] === 'inspectionDate'
                ||
                Object.keys(newStatus)[0] === 'completedActual'
                ||
                (Object.keys(newStatus)[0] === 'status' && newStatus.status === 'Rejected')
                ){
                ping()
            }
            toggleRecall()
            toggleAllInputsOff()
            loadingOff()
            //setNewJob( prevSate => ()) 
        }))
        .catch(err => handleAuthErr(err.message))
    }

    function handleSubmit(e){
        e.preventDefault()
        updateStatus(request._id, inputs)
    }
    function handleContactInfoSubmit(e){
        e.preventDefault()
        updateStatus(request._id, contactInfo)
    }

    function handleEstCostSubmit(e){
        e.preventDefault()
        updateStatus(request._id, estCost)
    }

    function handleActualCostSubmit(e){
        e.preventDefault()
        updateStatus(request._id, actualCost)
    }

    function handleInspectDaySubmit(e){
        e.preventDefault()
        let date = new Date(Date.now())
        let timezone = date.getTimezoneOffset()
        if(timezone>60){
            timezone = timezone/60
        }
        if(timezone<10){
            timezone = `0${timezone+1}`
            // +1 for daylight savings temp solution
        }
        let dateToSubmit  = new Date(Date.parse(`${inspectDate.inspectionDate}T00:00:00.000-${timezone}:00`))
        updateStatus(request._id, {inspectionDate: dateToSubmit})
    }

    function handleStartDaySubmit(e){
        e.preventDefault()
        let date = new Date(Date.now())
        let timezone = date.getTimezoneOffset()
        if(timezone>60){
            timezone = timezone/60
        }
        if(timezone<10){
            timezone = `0${timezone+1}`
            // +1 for daylight savings temp solution
        }
        let dateToSubmit  = new Date(Date.parse(`${startDate.estimatedStart}T00:00:00.000-${timezone}:00`))
        updateStatus(request._id, {estimatedStart: dateToSubmit})
    }

    function handleActualStartDaySubmit(e){
        e.preventDefault()
        let date = new Date(Date.now())
        let timezone = date.getTimezoneOffset()
        if(timezone>60){
            timezone = timezone/60
        }
        if(timezone<10){
            timezone = `0${timezone+1}`
            // +1 for daylight savings temp solution
        }
        let dateToSubmit  = new Date(Date.parse(`${commencedActual.startActual}T00:00:00.000-${timezone}:00`))
        updateStatus(request._id, {startActual: dateToSubmit})
    }

    function handleActualFinishDaySubmit(e){
        e.preventDefault()
        let date = new Date(Date.now())
        let timezone = date.getTimezoneOffset()
        if(timezone>60){
            timezone = timezone/60
        }
        if(timezone<10){
            timezone = `0${timezone+1}`
            // +1 for daylight savings temp solution
        }
        let dateToSubmit  = new Date(Date.parse(`${completedActual.completedActual}T00:00:00.000-${timezone}:00`))
        updateStatus(request._id, {completedActual: dateToSubmit})
    }

    function handleFinishDaySubmit(e){
        e.preventDefault()
        let date = new Date(Date.now())
        let timezone = date.getTimezoneOffset()
        if(timezone>60){
            timezone = timezone/60
        }
        if(timezone<10){
            timezone = `0${timezone+1}`
            // +1 for daylight savings temp solution
        }
        let dateToSubmit  = new Date(Date.parse(`${finishDate.estimatedFinish}T00:00:00.000-${timezone}:00`))

        updateStatus(request._id, {estimatedFinish: dateToSubmit})
    }

    function clearInspectDate(e){
        e.preventDefault()
        setInspectDate(prevInspectDate => (
            {inspectionDate: null}
        ))
    }

    function clearEstStart(e){
        e.preventDefault()
        setStartDate(prevState => (
            {estimatedStart: null}
        ))
    }
    function clearEstFinish(e){
        e.preventDefault()
        setFinishDate(prevInspectDate => (
            {estimatedFinish: null}
        ))
    }
    function clearActStart(e){
        e.preventDefault()
        setCommencedActual(prevInspectDate => (
            {startActual: null}
        ))
    }
    function clearActFinish(e){
        e.preventDefault()
        setCompletedActual(prevInspectDate => (
            {completedActual: null}
        ))
    }

    function handleChange(e){
        const { name, value } = e.target
        setInputs(prevInputs => (
            { ...prevInputs, [name]: value}
        ))
    }

    function handleContactInfoChange(e){
        const { name, value } = e.target
        setContactInfo((prevInputs) => (    
            {...prevInputs, poc:{...prevInputs.poc, [name]: value}}
        ));
    }

    function handleEstCostChange(e){
        const { name, value } = e.target
        setEstCost(prevInputs => (
            { ...prevInputs, [name]: value}
        ))
    }

    function handleActualCostChange(e){
        const { name, value } = e.target
        setActualCost(prevInputs => (
            { ...prevInputs, [name]: value}
        ))
    }

    function handleInspectionDateChange(e){
        const { name, value } = e.target
        setInspectDate(prevInputs => (
            { ...prevInputs, [name]: value}
        ))
    }
    
    function handleStartDateChange(e){
        const { name, value } = e.target
        setStartDate(prevInputs => (
            { ...prevInputs, [name]: value}
        ))
    }

    function handleFinishDateChange(e){
        const { name, value } = e.target
        setFinishDate(prevInputs => (
            { ...prevInputs, [name]: value}
        ))
    }

    function handleActualStartDateChange(e){
        const { name, value } = e.target
        setCommencedActual(prevInputs => (
            { ...prevInputs, [name]: value}
        ))
    }

    function handleActualFinishDateChange(e){
        const { name, value } = e.target
        setCompletedActual(prevInputs => (
            { ...prevInputs, [name]: value}
        ))
    }

    function toggleImage(){
        setImageRequested(prevState => (
            prevState = !prevState
        ))
    }

    function toggleEstCostUpdating(){
        setUpdatingEstCost(prevState => (
            prevState = !prevState
        ))
    }

    function toggleActualCostUpdating(){
        setUpdatingActualCost(prevState => (
            prevState = !prevState
        ))
    }

    function toggleUpdatingStatus(){
        setUpdatingStatus(prevState => (
            prevState = !prevState
        ))
    }
    
    function toggleUpdatingContactInfo(){
        setUpdatingContactInfo(prevState => (
            prevState = !prevState
        ))
    }

    function toggleInspectTimeUpdate(){
        setSettingInspectTime(prevState => (
            prevState = !prevState
        ))
    }

    function toggleStartTimeUpdating(){
        setUpdatingStartTime(prevState => (
            prevState = !prevState
        ))
    }
    
    function toggleFinishTimeUpdating(){
        setUpdatingFinishTime(prevState => (
            prevState = !prevState
        ))
    }
    function toggleActualStartTimeUpdating(){
        setUpdatingActualStartTime(prevState => (
            prevState = !prevState
        ))
    }
    function toggleActualFinishUpdating(){
        setUpdatingActualFinishTime(prevState => (
            prevState = !prevState
        ))
    }

    function convertDateFormat(dateToConvert){
        let date = new Date(dateToConvert);
        let convertedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
        return convertedDate
    }

    return(
        <div className="requestDisplayContainer">
            {request.accountEmail ? 
                <div autoFocus>
                    <div>Account Email: {request.accountEmail}</div>
                </div>
            :
                ""
            }
            <div autoFocus>
                <div>Job Type: {request.jobType}</div>
            </div>
            <div>
                <div className="statusDisplay">Status: <div className="statusText" style={{color: coloring(newJob.status ? newJob.status:request.status)}}>{newJob.status ? newJob.status:request.status}</div></div>
                <img onClick={toggleUpdatingStatus} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                {updatingStatus ? 
                    <form onSubmit={handleSubmit}>
                        <select className="statusOptions" name = 'status' onChange={handleChange} value = {inputs.status}>
                            {statusArray.map((status, i)=>(
                                <option key={i} value = {status}>{status}</option>
                            ))}
                        </select>
                        <button>Update Status</button>
                    </form>
                : ''}
            </div>
            <div className="contactContainer" style={{display: 'flex'}}>
                {updatingContactInfo ?
                    <div>
                        <form onSubmit={handleContactInfoSubmit}>
                            <div>
                                Contact First Name: <input
                                    type="text"
                                    onChange={handleContactInfoChange}
                                    name = 'contactFirstName'
                                    value={contactInfo.poc.contactFirstName}
                                />
                            </div>
                            <div>
                                Contact Last Name: <input
                                    type="text"
                                    onChange={handleContactInfoChange}
                                    name = 'contactLastName'
                                    value={contactInfo.poc.contactLastName}
                                />
                            </div>
                            <div>
                                Contact Phone#: 
                                <div style={{fontSize: 10}}>format of: '555-555-5555'</div>
                                <input
                                    //type="number"
                                    type="tel"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    onChange={handleContactInfoChange}
                                    name = 'contactPhone'
                                    value={contactInfo.poc.contactPhone}
                                />
                            </div>
                            <div>
                                Contact Email: <input
                                    type="email"
                                    onChange={handleContactInfoChange}
                                    name = 'contactEmail'
                                    value={contactInfo.poc.contactEmail}
                                />
                            </div>
                        <button>Submit</button>
                    </form>
                    </div>
                :   
                    <div>
                        <div>
                            Point of Contact: {newJob.poc ? newJob.poc.contactFirstName : request.poc.contactFirstName} {newJob.poc ? newJob.poc.contactLastName : request.poc.contactLastName}
                        </div>
                        <div>
                            Contact's Phone: {newJob.poc ? newJob.poc.contactPhone : request.poc.contactPhone}
                        </div>
                        <div>
                            Contact's Email: {newJob.poc ? newJob.poc.contactEmail : request.poc.contactEmail}
                        </div>
                    </div>
                }
                <img onClick={toggleUpdatingContactInfo} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
            </div>                       
                <div>
                    Job Location:
                    <div style={{paddingLeft: 20}}>
                        <div>{request.jobLocation.line1}</div>
                        <div>{request.jobLocation.line2 ? request.jobLocation.line2 : ''}</div>
                        <div>{request.jobLocation.city.charAt(0).toUpperCase()+request.jobLocation.city.slice(1)}, {request.jobLocation.state.toUpperCase()} {request.jobLocation.zip}</div>
                    </div>
                </div>
            
            <div>
                <div>Description: {request.description}</div>
            </div>
            <div>
                <div>Date Requested: {dateRequested}</div>
            </div>
            <div>
                {
                newJob.inspectionDate ? 
                <div>
                    Inspect For Est Date: {newJob.inspectionDate ? convertDateFormat(newJob.inspectionDate) : 'No Date Set'}
                    <img onClick={toggleInspectTimeUpdate} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                </div>
                    :
                <div>
                    Inspect For Est Date: {request.inspectionDate ? convertDateFormat(request.inspectionDate) : 'No Date Set'}
                    <img onClick={toggleInspectTimeUpdate} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                </div>
                }
                {settingInspectTime ? 
                <div>
                    <form onSubmit={handleInspectDaySubmit}>
                        <input
                            type="date"
                            onChange={handleInspectionDateChange}
                            name = 'inspectionDate'
                        />
                        <button>Submit</button>
                        <button onClick={clearInspectDate}>Clear Date</button>
                    </form>
                </div> : ''}
            </div>
            <div className="divider2"></div>
            <div>
            {
                newJob.estimatedStart ? 
                <div>
                    Estimated Start: {newJob.estimatedStart ? convertDateFormat(newJob.estimatedStart) : 'No Date Set'}
                    <img onClick={toggleStartTimeUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                </div>
                    :
                <div>
                    Estimated Start: {request.estimatedStart ? convertDateFormat(request.estimatedStart) : 'No Date Set'}
                    <img onClick={toggleStartTimeUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                </div>
                }
                {updatingStartTime ? 
                <div>
                    <form onSubmit={handleStartDaySubmit}>
                        <input
                            type="date"
                            onChange={handleStartDateChange}
                            name = 'estimatedStart'
                        />
                        <button>Submit</button>
                        <button onClick={clearEstStart}>Clear Date</button>
                    </form>
                </div> : ''}
            </div>
            <div>
                {
                    newJob.estimatedFinish ? 
                    <div>
                        Estimated Finish: {newJob.estimatedFinish ? convertDateFormat(newJob.estimatedFinish) : 'No Date Set'}
                        <img onClick={toggleFinishTimeUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                        :
                    <div>
                        Estimated Finish: {request.estimatedFinish ? convertDateFormat(request.estimatedFinish) : 'No Date Set'}
                        <img onClick={toggleFinishTimeUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                }
                {updatingFinishTime ? 
                <div>
                    <form onSubmit={handleFinishDaySubmit}>
                        <input
                            type="date"
                            onChange={handleFinishDateChange}
                            name = 'estimatedFinish'
                        />
                        <button>Submit</button>
                        <button onClick={clearEstFinish}>Clear Date</button>
                    </form>
                </div> : ''}
            </div>
            <div>
                {
                    newJob.estimatedCost ? 
                    <div>
                        Estimated Cost: {newJob.estimatedCost ? `$${newJob.estimatedCost}` : 'No Est. Cost Set'}
                        <img onClick={toggleEstCostUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                        :
                    <div>
                        Estimated Cost: {request.estimatedCost ? `$${request.estimatedCost}` : 'No Est. Cost Set'}
                        <img onClick={toggleEstCostUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                }
                {updatingEstCost ? 
                <div>
                    <form onSubmit={handleEstCostSubmit}>
                        <input
                            type="number"
                            onChange={handleEstCostChange}
                            name = 'estimatedCost'
                            min="0.01" 
                            step="0.01"
                        />
                        <button>Submit</button>
                    </form>
                </div> : ''}
            </div>
            <div className="divider2"></div>
            <div>
                {
                    newJob.startActual ? 
                    <div>
                        Actual Start: {newJob.startActual ? convertDateFormat(newJob.startActual) : 'No Date Set'}
                        <img onClick={toggleActualStartTimeUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                        :
                    <div>
                        Actual Start: {request.startActual ? convertDateFormat(request.startActual) : 'No Date Set'}
                        <img onClick={toggleActualStartTimeUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                }
                    {updatingActualStartTime ? 
                    <div>
                        <form onSubmit={handleActualStartDaySubmit}>
                            <input
                                type="date"
                                onChange={handleActualStartDateChange}
                                name = 'startActual'
                            />
                            <button>Submit</button>
                            <button onClick={clearActStart}>Clear Date</button>
                        </form>
                    </div> : ''}
            </div>
            <div>
                {
                    newJob.completedActual ? 
                    <div>
                        Actual Completed: {newJob.completedActual ? convertDateFormat(newJob.completedActual) : 'No Date Set'}
                        <img onClick={toggleActualFinishUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                        :
                    <div>
                        Actual Completed: {request.completedActual ? convertDateFormat(request.completedActual) : 'No Date Set'}
                        <img onClick={toggleActualFinishUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                }
                    {updatingActualFinishTime ? 
                    <div>
                        <form onSubmit={handleActualFinishDaySubmit}>
                            <input
                                type="date"
                                onChange={handleActualFinishDateChange}
                                name = 'completedActual'
                            />
                            <button>Submit</button>
                            <button onClick={clearActFinish}>Clear Date</button>
                        </form>
                    </div> : ''}
            </div>
            <div>
                {
                    newJob.actualCost ? 
                    <div>
                        Actual Cost: {newJob.actualCost ? `$${newJob.actualCost}` : 'No Cost Set'}
                        <img onClick={toggleActualCostUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                        :
                    <div>
                        Actual Cost: {request.actualCost ? `$${request.actualCost}` : 'No Cost Set'}
                        <img onClick={toggleActualCostUpdating} src = {require ('../imgs/editIcon.png')} alt = '' className="editIcon"></img>
                    </div>
                }
                {updatingActualCost ? 
                <div>
                    <form onSubmit={handleActualCostSubmit}>
                        <input
                            type="number"
                            onChange={handleActualCostChange}
                            name = 'actualCost'
                            min="0.01" 
                            step="0.01"
                        />
                        <button>Submit</button>
                    </form>
                </div> : ''}
            </div>
                            {!request.img ? <div>No Files Attached</div> :
                            <div>
                                <button onClick = {toggleImage}>View Job Image</button>
                                <div>
                                {imageRequested ? <a href={`${transProtocol}://${connectServerString}/jobImage/Images/${request._id}`} target="_blank" rel="noopener noreferrer">
                                    <img className="jobImage" onError={handleBrokenImage} src={`${transProtocol}://${connectServerString}/jobImage/Images/${request._id}`} alt=''/>
                                    </a>: ''}
                                </div>
                            </div>}
                            <div>
                                <button onClick = {updateLOMRefAndToggle}>View LOM</button>
                            </div>
                            <div>
                                <button onClick = {viewAssigned}>View Assigned</button>
                            </div>
                            <button onClick={updateRefAndToggle}>Notes</button>
                            <AssignedList assignedList = {request.assignmentList} show = {showAssignedList} toggleAssignedList = {viewAssigned} address = {request.jobLocation} jobID = {request._id}/>
        </div>
    )
}