import React, {useContext} from 'react'
import { UserContext } from "../context/UserProvider";

export default function GetAllReportJob(props){
    const {job} = props
    const {
        coloring
    } = useContext(UserContext)

    function convertDateFormat(dateToConvert){
        let date = new Date(dateToConvert);
        let convertedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
        return convertedDate
    }

    const currentDate = new Date(convertDateFormat(Date.now()))
   
    const requestDate = new Date(convertDateFormat(job.requestDate))
    
    const timeDiff = currentDate.getTime() - requestDate.getTime()
    
    const dayDiff = timeDiff / (1000 * 3600 * 24)
    
    const greaterThan = '>'


    return(
        <div className='statusJob'>
            <div style={{fontFamily:'italic', fontSize: 15, marginLeft: 20, marginRight:20, color: coloring(job.status)}}>{job.status}</div>
            <div className='statusJobInfo'>
                <div style={{display: 'flex'}}>
                    <div style={{fontFamily:'italic', fontStyle: 'oblique'}}> {dayDiff.toFixed(0)} DAYS SINCE REQUESTED</div>
                    
                </div>
                <div style={{display: 'flex'}}>
                    <div style={{fontFamily:'italic', fontSize: 15}}>{job.submittedByLastName} / {job.submittedByFirstName}</div>
                    <div style={{fontFamily:'italic', fontSize: 15, marginLeft: 20, marginRight: 20}}>{greaterThan}</div>
                    <div style={{fontFamily:'italic', fontSize: 15}}>Contact: {job.poc.contactLastName} / {job.poc.contactFirstName}</div>
                    <div style={{fontFamily:'italic', fontSize: 15, marginLeft: 20}}>Phone#: {job.poc.contactPhone}</div>
                </div>
                <div style={{display: 'flex', marginTop: 10}}>
                    <div style={{fontFamily:'italic', fontSize: 15}}>{job.jobType}</div>
                    <div style={{fontFamily:'italic', fontSize: 15, marginLeft: 20}}>Requested: {convertDateFormat(job.requestDate)}</div>
                </div>
                <div style={{fontFamily:'italic', fontSize: 15, marginTop: 10}}>
                    Description: {job.description}
                </div>
            </div>
        </div>
    )
}