import React, {useState,useContext,useEffect} from "react";
import AdminJob from "./AdminJob";
import { UserContext } from "../context/UserProvider";
import ReactDOM from "react-dom";
import axios from "axios";
import ViewNotes from "./ViewNotes";
import JobLOM from "./JobLom/JobLOM";

export default function JobSearch(props){
    
    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    const { 
        connectServerString,
        transProtocol,
        statusArray,
        loadingOff,
        loadingOn,
        handleAuthErr,
        jobTypeArray
    } = useContext(UserContext)

    const [searchResults, setSearchResults] = useState([])

    const [lomAddress, setLomAddress] = useState({})

    const [searchType, setSearchType] = useState({
        searchTypeValue:'address'
    })
    const [addressSearchLine, setAddressSearchLine] = useState({
        addressLine:'line1'
    })
    const [searchParam, setSearchParam] = useState({
        searchValue:''
    })

    const [altInputs, setAltInputs] = useState({
        altJobType: ''
    })
    
    const [showNotes, setShowNotes] = useState(false)

    const [showLOM, setShowLOM] = useState(false)

    const [recall, setRecall] = useState(false)

    const [refID, setRefID] = useState('')

    const [lomRefID, setLOMRefID] = useState('')

    const [sortOptions, setSortOptions] = useState({
        sortBy: 'requestDate',
        order: false
    })

    function handleSortChange(e){
        const {name, value} = e.target
        setSortOptions((prevState) => ({...prevState, [name]: value}))
    }

    function toggleOrder(){
        setSortOptions(prevState => ({...prevState, order: !prevState.order}))
    }

    useEffect(()=>{
        setSearchResults(prevState => {
            return [...prevState.sort((a, b) => {
                    if(sortOptions.sortBy === 'contactLastName'||sortOptions.sortBy === 'contactEmail'){
                        let nameA = a.poc[sortOptions.sortBy].toUpperCase()
                        let nameB = b.poc[sortOptions.sortBy].toUpperCase()
                        
                        return(
                            (nameA > nameB) 
                            ? sortOptions.order ? -1 : 1
                            : (nameA < nameB) 
                            ? sortOptions.order ? 1 : -1 
                            : 0
                        )
                    }else{
                        let nameA = a[sortOptions.sortBy].toUpperCase()
                        let nameB = b[sortOptions.sortBy].toUpperCase()

                        return(
                            (nameA > nameB) 
                            ? sortOptions.order ? -1 : 1 
                            : (nameA < nameB) 
                            ? sortOptions.order ? 1 : -1  
                            : 0
                        )
                    }
            })]
        })
    },[sortOptions])

    //////////////////////////////////
    function updateRefID(newRefID){
        setRefID(newRefID)
    }

    function updateLOMRefID(newRefID, newLomAddress){
        setLOMRefID(newRefID)
        setLomAddress(newLomAddress)
    }

    function toggleRecall(){
        setRecall(prevState => !prevState)
    }

    function toggleNotes(){
        setShowNotes(prevState => !prevState)
    }
    function toggleNotesOn(){
        setShowNotes(true)
    }

    function toggleLOM(){
        setShowLOM(prevState => !prevState)
    }

    function inputSelector(type){
        if(type === 'jobType'){
            return(
                <select className="searchTypeSelect" name = 'searchValue' onChange={handleChange}>
                    <option value={''}>Select</option>
                    {jobTypeArray.map((type, i)=>(
                        <option key={i} value = {type}>{type}</option>
                    ))}
                </select>
            )
        }else if(type === 'status'){
            return(
                <select className="searchTypeSelect" name = 'searchValue' onChange={handleChange}>
                    <option value={''}>Select</option>
                    {statusArray.map((status, i)=>(
                        <option key = {i} value = {status}>{status}</option>
                    ))}
                </select>
            )
        }else if(type === 'address'){
            return(
                <div style={{display: 'inline-block'}}>
                    <div style={{marginBottom: 10}}>Address Line: 
                        <select 
                            style={{marginLeft: 5}}
                            onChange={handleAddressSearchLineChange}
                            value={addressSearchLine.addressLine}
                            name='addressLine'
                        >
                            <option value = {`line1`}>Street</option>
                            <option value = {`city`}>City</option>
                            <option value = {`state`}>State</option>
                            <option value = {`zip`}>Zip</option>
                        </select>
                    </div>
                    <input
                        onChange={handleChange}
                        name='searchValue'
                        type="text"
                        placeholder="Search"
                        autoFocus={true}
                    />
                </div>
            )
        }else{
            return(
                <input
                    onChange={handleChange}
                    name='searchValue'
                    type="text"
                    placeholder="Search"
                    autoFocus={true}
                />
            )
        }
    }
    
    function handleSubmit(e){
        e.preventDefault()
        loadingOn()
        if(searchType.searchTypeValue === 'pocName'){
            const strArr = searchParam.searchValue.split(" ")
            const filteredArr = strArr.filter((i)=> i !== '')
            const firstName = '^' + filteredArr[0]
            let lastName = ''
            if(filteredArr[1]){
                lastName = lastName + '^' + filteredArr[1]
                userAxios.get(`${transProtocol}://${connectServerString}/api/access/work/searchByName/${firstName}/${lastName}`)
                    .then(res => {
                        if(!res.data.errMsg){
                            setSearchResults(prevSearchResults => (
                                [
                                    ...res.data
                                ]))
                            loadingOff()
                        }else{
                        setSearchResults([])
                        handleAuthErr(res.data.errMsg)
                        }
                    })
                    .catch(err => {
                        handleAuthErr(err.message)
                        setSearchResults([])
                    })
            }else{
                userAxios.get(`${transProtocol}://${connectServerString}/api/access/work/searchByLastName/${firstName}`)
                    .then(res => {
                        if(!res.data.errMsg){
                            setSearchResults(prevSearchResults => (
                                [
                                    ...res.data
                                ]))
                            loadingOff()
                        }else{
                        setSearchResults([])
                        handleAuthErr(res.data.errMsg)
                        }
                    })
                    .catch(err => {
                        handleAuthErr(err.message)
                        setSearchResults([])
                    })
            }
        }else if(searchType.searchTypeValue === 'jobType' && searchParam.searchValue === 'Other'){
            userAxios.get(`${transProtocol}://${connectServerString}/api/access/work/search/${searchType.searchTypeValue}/${altInputs.altJobType}`)
            .then(res => {
                if(!res.data.errMsg){
                    setSearchResults(prevSearchResults => (
                        [
                            ...res.data
                        ]))
                    loadingOff()
                }else{
                   setSearchResults([])
                   handleAuthErr(res.data.errMsg)
                }
            })
            .catch(err =>{
                handleAuthErr(err.message)
                setSearchResults([])
            })
        }else if(searchType.searchTypeValue === 'address'){
            userAxios.get(`${transProtocol}://${connectServerString}/api/access/work/search/${searchType.searchTypeValue}/${searchParam.searchValue}/${addressSearchLine.addressLine}`)
            .then(res => {
                if(!res.data.errMsg){
                    setSearchResults(prevSearchResults => (
                        [
                            ...res.data
                        ]))
                    loadingOff()
                }else{
                   setSearchResults([])
                   handleAuthErr(res.data.errMsg)
                }
            })
            .catch(err =>{
                console.log(err)
                handleAuthErr(err.message)
                setSearchResults([])
            })
        }else{
            userAxios.get(`${transProtocol}://${connectServerString}/api/access/work/search/${searchType.searchTypeValue}/${searchParam.searchValue}`)
            .then(res => {
                if(!res.data.errMsg){
                    setSearchResults(prevSearchResults => (
                        [
                            ...res.data
                        ]))
                    loadingOff()
                }else{
                   setSearchResults([])
                   handleAuthErr(res.data.errMsg)
                }
            })
            .catch(err =>{
                handleAuthErr(err.message)
                setSearchResults([])
            })
        }
    }

    function handleChange(e){
        const {name, value} = e.target
        setSearchParam((prevState) => ({...prevState, [name]: value}))
    }

    function handleAltChange(e){
        const { name, value } = e.target;
        setAltInputs((prevInputs) => (
            { ...prevInputs, [name]: value}
        ));
    }

    function handleTypeChange(e){
        const {name, value} = e.target
        setSearchType((prevState) => ({...prevState, [name]: value}))
    }

    function handleAddressSearchLineChange(e){
        const {name, value} = e.target
        setAddressSearchLine((prevState) => ({...prevState, [name]: value}))
    }

    // const printResults = () =>{     
    //     //console.log('print');
         
    //     let printContents = document.getElementsByClassName('resultsContainer');
    //     console.log('reached')
    //     let blankDiv = document.getElementById('divToPrint').contentWindow
    //     blankDiv.open()
    //     blankDiv.document.write(printContents.innerHTML);
    //     blankDiv.focus();
    //     blankDiv.print();
         
    //   }

    const results = searchResults.map(job => (
        <AdminJob
            key = {job._id} 
            request = {job}
            updateRef = {updateRefID}
            updateLOMRef = {updateLOMRefID}  
            toggleNotes = {toggleNotesOn}
            toggleRecall = {toggleRecall}
            toggleLOM = {toggleLOM}
        />
    ))
    if(!props.show)return null
    return ReactDOM.createPortal(
        <div className="jobSearchContainer">
            <h2 onClick={props.closeModal} className = 'cyanExitButton'>X</h2>
            {/* <div className="printResults">
                <div>Print Results</div>
                <img src = {require ('../imgs/printerIcon.png')} alt = '' className="printerIcon"></img>
            </div> */}
            <div className="jobSearchHead">
                <form onSubmit={handleSubmit} className = 'searchForm'>
                    <div style={{marginRight: 5}}>Find By:
                        <select className="searchTypeSelect" 
                            name = 'searchTypeValue' 
                            onChange={handleTypeChange}
                            value={searchType.searchTypeValue}
                            style={{marginRight: 5, marginLeft: 5}}
                        >
                            <option value={'address'}>Address</option>
                            <option value={'status'}>Status</option>
                            <option value={'jobType'}>Job Type</option>
                            <option value={'pocName'}>Contact Name</option>
                            <option value={'nested'}>Contact Email</option>
                        </select>
                    </div>
                    <div style={{marginTop: 10}}>
                        {inputSelector(searchType.searchTypeValue)}
                        <button style={{marginLeft: 5}}>Search</button>
                    </div>
                    {searchType.searchTypeValue === 'jobType' && searchParam.searchValue === 'Other' 
                        ? 
                        <input
                            onChange={handleAltChange}
                            name='altJobType'
                            value={altInputs.altJobType}
                        /> 
                    : 
                        ''
                    }
                </form>
                <div style={{marginLeft: 5}}>Sort By: 
                    <select
                        name = 'sortBy' 
                        onChange={handleSortChange}
                        style={{marginRight: 5, marginLeft: 5}}
                        value = {sortOptions.sortBy}
                    >
                        <option value={'requestDate'}>Request Date</option>
                        <option value={'status'}>Status</option>
                        <option value={'jobType'}>Job Type</option>
                        <option value={'contactLastName'}>Contact Name</option>
                        <option value={'contactEmail'}>Contact Email</option>
                    </select>
                </div>
                <div onClick={toggleOrder} style={{cursor:'pointer'}}>
                    <img style={{marginRight: 5, cursor:'pointer'}} src = {require ('../imgs/ascending-sorting.png')} alt = '' className="orderIcon"></img>
                    {sortOptions.order ? 'Descending' : 'Ascending'}
                </div>
            </div>
            <div>{results.length} Results Found</div>
            <div className="resultsContainer">
                {
                    results.length !==0 ? 
                        results
                        : 
                        <div>No Results Found</div>
                }
            </div>
            <ViewNotes show = {showNotes} toggleNotes = {toggleNotes} refID = {refID} reset = {recall}/>
            <JobLOM show = {showLOM} toggleLOM = {toggleLOM} refID = {lomRefID} address = {lomAddress}/>
        </div>,
        document.getElementById("searchPortal")
    )
}