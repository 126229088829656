import React from "react";

export default function MetricReport(props){
    const {jobList} = props 

    let metrics = {
        noStatus: 0,
        jobsRequested: 0,
        jobsRejected: 0,
        jobsApprovedtobeEST: 0,
        jobsScheduledforEST: 0,
        estimated: 0,
        customerAcceptedEST: 0,
        scheduledToExecute: 0,
        started: 0,
        completed: 0,
        totalEstimates: 0,
        totalActualCost: 0,
        totalJobsUnderestimatedCost: 0,
        totalJobsUnderestimatedTime: 0
    }
// eslint-disable-next-line
    jobList.map((job)=>{
        if(job.status === 'Approved to be Est'){
            metrics.jobsApprovedtobeEST++
            }else if(job.status === 'Requested'){
                metrics.jobsRequested++
            }else if(job.status === 'Rejected'){
                metrics.jobsRejected++
            }else if(job.status === 'Scheduled for Est'){
                metrics.jobsScheduledforEST++
            }else if(job.status === 'Estimated'){
                metrics.estimated++
            }else if(job.status === 'Customer Accepted Est'){
                metrics.customerAcceptedEST++
            }else if(job.status === 'Scheduled to Execute'){
                metrics.scheduledToExecute++
            }else if(job.status === 'Started'){
                metrics.started++
            }else if(job.status === 'Completed'){
                metrics.completed++
            }else{
                metrics.noStatus++
            }
        if(job.estimatedCost){
            metrics.totalEstimates = metrics.totalEstimates + job.estimatedCost
        }   
        if(job.actualCost){
            metrics.totalActualCost = metrics.totalActualCost + job.actualCost
        }
        if(job.actualCost && job.estimatedCost){
            if(job.actualCost > job.estimatedCost){
                metrics.totalJobsUnderestimatedCost++
            }
        }       
        if(job.completedActual && job.estimateFinish){
            if(job.completedActual > job.estimateFinish){
                metrics.totalJobsUnderestimatedTime++
            }
        }
        
    })
    
    return(
        <div style={{backgroundColor: 'white', color: 'black', width: '100%', borderRadius: 5, padding: 10}}>
            <div>
                Total Jobs Requested: {metrics.jobsRequested}
            </div>
            <div>
                Total Jobs Approved to be Estimated: {metrics.jobsApprovedtobeEST}
            </div>
            <div>
                Total Jobs Scheduled to be Estimated: {metrics.jobsScheduledforEST}
            </div>
            <div>
                Total Jobs Estimated: {metrics.estimated}
            </div>
            <div>
                Total Jobs Estimate Accepted by Customer: {metrics.customerAcceptedEST}
            </div>
            <div>
                Total Jobs Scheduled to Execute: {metrics.scheduledToExecute}
            </div>
            <div>
                Total Jobs Started: {metrics.started}
            </div>
            <div>
                Total Jobs Rejected: {metrics.jobsRejected}
            </div>
            <div>
                Total Jobs Completed: {metrics.completed}
            </div>
            <div>
                Total of Estimates: ${ metrics.totalEstimates}
            </div>
            <div>
                Total of Actual Costs: ${ metrics.totalActualCost}
            </div>
            <div>
                Total Jobs Underestimated Costs: { metrics.totalJobsUnderestimatedCost }
            </div>
            <div>
                Total Jobs Underestimated Time: { metrics.totalJobsUnderestimatedTime }
            </div>
        </div>
    )
}