import React, {useEffect, useState, useContext} from "react";
import ReactDOM from 'react-dom'
import AdminJob from "./AdminJob";
import {UserContext} from '../context/UserProvider.js'
import axios from "axios";
import ViewNotes from "./ViewNotes";
import JobLOM from "./JobLom/JobLOM";

export default function SchedulerJob(props){
    const {jobID, close} = props

    const [job, setJob] = useState([])

    const [showNotes, setShowNotes] = useState(false)

    const [showLOM, setShowLOM] = useState(false)
    
    const [recall, setRecall] =useState(false)

    const [refID, setRefID] = useState('')

    const [lomRefID, setLOMRefID] = useState('')

    const [lomAddress, setLomAddress] = useState({})

    function toggleRecall(){
        setRecall(prevState => !prevState)
    }

    const { 
        connectServerString,
        transProtocol
      } = useContext(UserContext)

    const userAxios = axios.create()

    userAxios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`
        return config
    })

    useEffect(()=>{
        userAxios.get(`${transProtocol}://${connectServerString}/api/access/work/search/_id/${jobID}`)
            
            .then(res => {
                if(res.status === 200){
                    setJob(prevSearchResults => (
                        [
                            ...res.data
                        ]))
                }else{
                    setJob([])
                }
            })
            .catch(err => 
                console.log(err))
                // eslint-disable-next-line
    },[])

    const result = job.map(job => (
        <AdminJob
            key = {job._id} 
            request = {job}
            updateRef = {updateRefID} 
            toggleNotes = {toggleNotesOn}
            toggleRecall = {toggleRecall}
            updateLOMRef = {updateLOMRefID} 
            toggleLOM = {toggleLOM}
        />
    ))

    function toggleNotes(){
        setShowNotes(prevState => !prevState)
    }
    function toggleLOM(){
        setShowLOM(prevState => !prevState)
    }
    function toggleNotesOn(){
        setShowNotes(true)
    }
    function updateRefID(newRefID){
        setRefID(newRefID)
    }

    function updateLOMRefID(newRefID, newLomAddress){
        setLOMRefID(newRefID)
        setLomAddress(newLomAddress)
    }
    
    return ReactDOM.createPortal(
        <div className="schedulerJobContainer">
            <h2 className='cyanExitButton' onClick={close} style={{marginLeft: 'auto'}}>X</h2>
            {result}
            <ViewNotes show = {showNotes} toggleNotes = {toggleNotes} refID = {refID} reset = {recall}/>
            <JobLOM show = {showLOM} toggleLOM = {toggleLOM} refID = {lomRefID} address = {lomAddress}/>
        </div>,
        document.getElementById("schedulerEvent")
    )
}