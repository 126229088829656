import React, {useContext, useState, useEffect} from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import {UserContext} from '../context/UserProvider.js'
import GetAllReportJob from './GetAllReportJob.js'
import MetricReport from "./MetricReport.js";

const userAxios = axios.create()

userAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = `Bearer ${token}`
    return config
})



export default function Report(props){

    const [jobList, setJobList] = useState([])
    const [dateRange, setDateRange] = useState({
        from:'',
        to:''
    })

    const [metricView, setMetricView] = useState(false)

    const [sortOptions, setSortOptions] = useState({
        sortBy: '',
        order: false
    })

    function handleSortChange(e){
        const {name, value} = e.target
        setSortOptions((prevState) => ({...prevState, [name]: value}))
    }

    function toggleOrder(){
        setSortOptions(prevState => ({...prevState, order: !prevState.order}))
    }

    useEffect(()=>{
        setJobList(prevState => {
            return [...prevState.sort((a, b) => {
                    if(sortOptions.sortBy === 'contactLastName'||sortOptions.sortBy === 'contactEmail'){
                        let nameA = a.poc[sortOptions.sortBy].toUpperCase()
                        let nameB = b.poc[sortOptions.sortBy].toUpperCase()
                        
                        return(
                            (nameA > nameB) 
                            ? sortOptions.order ? -1 : 1
                            : (nameA < nameB) 
                            ? sortOptions.order ? 1 : -1 
                            : 0
                        )
                    }else{
                        let nameA = a[sortOptions.sortBy].toUpperCase()
                        let nameB = b[sortOptions.sortBy].toUpperCase()

                        return(
                            (nameA > nameB) 
                            ? sortOptions.order ? -1 : 1 
                            : (nameA < nameB) 
                            ? sortOptions.order ? 1 : -1  
                            : 0
                        )
                    }
            })]
        })
    },[sortOptions])

    function toggleMetricViewOn(){
        getJobs()
        setMetricView(true)
    }
    function toggleMetricViewOff(){
        getJobs()
        setMetricView(false)
    }

    function handleChange(e){
        const { name, value } = e.target
        setDateRange(prevInputs => (
            { ...prevInputs, [name]: value}
        ))
    }

    const {
        connectServerString,
        transProtocol,
        loadingOn,
        loadingOff,
        handleAuthErr
    } = useContext(UserContext)



    function getJobs(){
        loadingOn()
        if(dateRange.from !== '' && dateRange.to !== ''){
            let date = new Date(Date.now())
            let timezone = date.getTimezoneOffset()
            if(timezone>60){
                timezone = timezone/60
            }
            if(timezone<10){
                timezone = `0${timezone}`
            }
            let fromDate  = new Date(Date.parse(`${dateRange.from}T00:00:00.000-${timezone}:00`))
            let toDate  = new Date(Date.parse(`${dateRange.to}T24:00:00.000-${timezone}:00`))
            userAxios.get(`${transProtocol}://${connectServerString}/api/access/work/byDate/${fromDate}/${toDate}`)
            .then(res => {
                setJobList(prevAdminJobsList => (
                [
                    ...res.data
                ]))
                loadingOff()
            })
            .catch(err => 
                handleAuthErr(err.message))
        }else{
            userAxios.get(`${transProtocol}://${connectServerString}/api/access/work/`)
            .then(res => {
                
                setJobList(prevAdminJobsList => (
                [
                    ...res.data
                ]))
                loadingOff()
            })
            .catch(err => 
                handleAuthErr(err.message))
        }
    }

    if(!props.show)return null
    return ReactDOM.createPortal(
        <div className="reportContainer">
            <h2 className="cyanExitButton" onClick={props.closeModal} >X</h2>
            <div className="jobSearchHead">
                <div style={{display: 'flex'}}>
                    <div>From: <input
                            type="date"
                            onChange={handleChange}
                            name = 'from'
                            value={dateRange.from}
                        />
                    </div>
                    <div style={{marginLeft: 20}}>To: <input
                            type="date"
                            onChange={handleChange}
                            name = 'to'
                            value={dateRange.to}
                        />
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', marginTop: 10, marginBottom: 5, marginLeft: -200}}>
                <button onClick={toggleMetricViewOff} style={{marginLeft: 20, backgroundColor: metricView ? 'grey' : 'blue', color: 'white'}}>Detailed</button>
                <button onClick={toggleMetricViewOn} style={{backgroundColor: metricView ? 'blue' : 'grey', color: 'white'}}>Summary</button>
            </div>
            <div style={{display: 'flex'}}>
                <div style={{marginLeft: 5}}>Sort By: 
                    <select
                        name = 'sortBy' 
                        onChange={handleSortChange}
                        style={{marginRight: 5, marginLeft: 5}}
                        value = {sortOptions.sortBy}
                    >
                        <option value={''}>Select</option>
                        <option value={'status'}>Status</option>
                        <option value={'jobType'}>Job Type</option>
                        <option value={'contactLastName'}>Contact Name</option>
                        <option value={'requestDate'}>Request Date</option>
                    </select>
                </div>
                <div onClick={toggleOrder} style={{cursor:'pointer'}}>
                    <img style={{marginRight: 5, cursor:'pointer'}} src = {require ('../imgs/ascending-sorting.png')} alt = '' className="orderIcon"></img>
                    {sortOptions.order ? 'Descending' : 'Ascending'}
                </div>
            </div>
            <div style={{marginTop: 15}}>{jobList.length} Results Found</div>
            <div className="reportViewContainer">
                {metricView ?
                    <MetricReport jobList = {jobList}/>
                    :
                    jobList.map(report => (
                        <GetAllReportJob job = {report} key = {report._id}/>
                    ))
                }
            </div>
        </div>,
        document.getElementById("reportView")
    )
}