import React, {useState, useContext} from 'react'
import { UserContext } from '../context/UserProvider';

export default function PasswordChangeForm(){

    const { updatePassword, user: {_id}, logout} = useContext(UserContext)

    const [inputs, setInputs] = useState({
        password:''
    })
    const [confirmation, setConfirmation] = useState({
        confirmPassword:''
    })
    const [showPass, setShowPass] = useState(false)
    const [passwordMatchMessage, setPasswordMatchMessage] = useState('')

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    }

    function handleConfirmationChange(e){
        const { name, value } = e.target;
        setConfirmation((prevConfirmation) => (
            { ...prevConfirmation, [name]: value}
        ));
    }


    function handleSubmit(e) {
        e.preventDefault();
        if(confirmation.confirmPassword === inputs.password && inputs.password !== ''){
            updatePassword(_id, inputs) 
        }
        else{
            setPasswordMatchMessage('Password Fields Must Match')
        }
      }

    function ToggleShow(){
        setShowPass(prevShowPass => !prevShowPass )
    }
    // don't forget to add current password auth before change
    return(
        <div style={{display: 'flex', flexDirection: 'column'}}>
            
            <form onSubmit = {handleSubmit}>
                <input
                    placeholder="Set Password"
                    autoFocus
                    type={showPass ? 'text' : 'password'}
                    name='password'
                    value={inputs.password}
                    onChange={handleChange}
                />
                <div onClick={ToggleShow} style={{
                        display: 'inline-block',
                        color: 'black',
                        marginLeft: -40,
                        height: 20,
                        paddingTop: 10,
                        fontSize: 12
                        }}>
                        {showPass ? 'Hide' : 'Show'}
                </div>
                <input
                    placeholder="Confirm Password"
                    autoFocus
                    type={showPass ? 'text' : 'password'}
                    name='confirmPassword'
                    value={confirmation.confirmPassword}
                    onChange={handleConfirmationChange}
                />
                <div onClick={ToggleShow} style={{
                        display: 'inline-block',
                        color: 'black',
                        marginLeft: -40,
                        height: 20,
                        paddingTop: 10,
                        fontSize: 12
                        }}>
                        {showPass ? 'Hide' : 'Show'}
                </div>
                <div>
                    <button>Update Password</button>
                </div>
                <div>
                    <button onClick={logout}>Log Out</button>
                </div>
                <p style={{color: 'red'}}>{passwordMatchMessage}</p>
            </form>
        </div>
    )

}