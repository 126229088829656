import React from "react";
import UserRequestDisplay from "./UserRequestDisplay";

export default function AccountRequestsView(props){

    const {jobs} = props

    const jobRequests = jobs.map((job) => {
        return(
            <UserRequestDisplay request = {job} key={job._id}/>
        )
    })

    return(
        <div>
            {jobRequests}
        </div>
    )
}