import React from "react";

export default function Note(props){
    const {note, i} = props
    const dateChanged = new Date(note.dateChanged).toLocaleString()

    if(note.addedNote){
        return(
            <div className="noteContainer">
                <div style={{fontWeight: 'bold', marginRight: 10}}>{i}.</div>
                <div>
                    <div>Added By: {note.madeBy}</div>
                    <div className="addedNote">Note: {note.addedNote}</div>
                    <div style={{color: 'blue', fontWeight: 'bold'}}>Changed On: {dateChanged}</div>
                </div>
            </div>
        )   
    }else{
        if(
            note.fieldChanged === 'estimatedCost' 
            || 
            note.fieldChanged === 'actualCost'
        ){
            return(
                <div className="noteContainer">
                    <div style={{fontWeight: 'bold', marginRight: 10}}>{i}.</div>
                    <div>
                        <div>Changed By: {note.madeBy}</div>
                        <div>Field Changed: {note.fieldChanged}</div>
                        {note.changedFrom ? <div>From: ${note.changedFrom}</div> : <div style={{color: 'red', fontWeight: 'bold'}}>First Change to this Field</div>}
                        <div>To: ${note.changedTo}</div>
                        <div style={{color: 'blue', fontWeight: 'bold'}}>Changed On: {dateChanged}</div>
                    </div>
                </div>
            )
        }else if(note.fieldChanged === 'poc'){
            return(
                <div className="noteContainer">
                    <div style={{fontWeight: 'bold', marginRight: 10}}>{i}.</div>
                    <div>
                        <div>Changed By: {note.madeBy}</div>
                        <div>Field Changed: Contact Info</div>
                        <div>From:
                            <div style={{marginLeft: 30}}> 
                                <div>{note.pocChangedFrom.contactFirstName} {note.pocChangedFrom.contactLastName}</div>
                                <div>{note.pocChangedFrom.contactPhone}</div>
                                <div>{note.pocChangedFrom.contactEmail}</div>
                            </div>
                        </div>
                        <div>To:
                            <div style={{marginLeft: 30}}> 
                                <div>{note.pocChangedTo.contactFirstName} {note.pocChangedTo.contactLastName}</div>
                                <div>{note.pocChangedTo.contactPhone}</div>
                                <div>{note.pocChangedTo.contactEmail}</div>
                            </div>
                        </div>
                        <div style={{color: 'blue', fontWeight: 'bold'}}>Changed On: {dateChanged}</div>
                    </div>
                </div>
            )
        }else if(note.fieldChanged === 'address'){
            
            return(
                <div className="noteContainer">
                    <div style={{fontWeight: 'bold', marginRight: 10}}>{i}.</div>
                    <div>
                        <div>Changed By: {note.madeBy}</div>
                        <div>Field Changed: {note.fieldChanged}</div>
                        <div>From: </div>
                            <div style={{marginLeft:20}}>
                                <div>{note.changedFrom.line1}</div>
                                {note.changedFrom.line2 ? <div>{note.changedFrom.line2}</div> : ''}
                                <div>{note.changedFrom.city}, {note.changedTo.state}</div>
                                <div>{note.changedFrom.zip}</div>
                            </div>
                        <div>To: </div>
                            <div style={{marginLeft:20}}>
                                <div>{note.changedTo.line1}</div>
                                {note.changedTo.line2 ? <div>{note.changedTo.line2}</div> : ''}
                                <div>{note.changedTo.city}, {note.changedTo.state}</div>
                                <div>{note.changedTo.zip}</div>
                            </div>
                        <div style={{color: 'blue', fontWeight: 'bold'}}>Changed On: {dateChanged}</div>
                    </div>
                </div>
            )
        }else if(note.fieldChanged === 'memberList'){
            if(note.memberAdded){
                return(
                    <div className="noteContainer">
                        <div style={{fontWeight: 'bold', marginRight: 10}}>{i}.</div>
                        <div>
                            <div>Changed By: {note.madeBy}</div>
                            <div style={{display: 'flex'}}>
                                <div style={{fontWeight: 'bold', marginRight: 10, color: 'green'}}>Assigned:</div>
                                <div>{note.memberAdded}</div>
                            </div>
                            <div style={{color: 'blue', fontWeight: 'bold'}}>Changed On: {dateChanged}</div>
                        </div>
                        
                    </div>
                )
            }else{
                return(
                    <div className="noteContainer">
                        <div style={{fontWeight: 'bold', marginRight: 10}}>{i}.</div>
                        <div>
                            <div>Changed By: {note.madeBy}</div>
                            <div style={{display: 'flex'}}>
                                <div style={{fontWeight: 'bold', marginRight: 10, color: 'red'}}>Unassigned:</div>
                                <div>{note.memberRemoved}</div>
                            </div>
                            <div style={{color: 'blue', fontWeight: 'bold'}}>Changed On: {dateChanged}</div>
                        </div>
                        
                    </div>
                )
            }
        }else{
            let changedTo = ''
            let changedFrom = ''
            if(note.changedTo){
                changedTo = new Date(note.changedTo).toLocaleDateString()
            }else{
                changedTo = 'null'
            }
            
            changedFrom = new Date(note.changedFrom).toLocaleDateString()

            return(
                <div className="noteContainer">
                    <div style={{fontWeight: 'bold', marginRight: 10}}>{i}.</div>
                    <div>
                        <div>Changed By: {note.madeBy}</div>
                        <div>Field Changed: {note.fieldChanged}</div>
                        {note.changedFrom ? <div>From: {changedFrom !== 'Invalid Date' ? changedFrom : note.changedFrom}</div> : <div style={{color: 'red', fontWeight: 'bold'}}>First Change to this Field</div>}
                        <div>To: {changedTo !== 'Invalid Date' ? changedTo : note.changedTo}</div>
                        <div style={{color: 'blue', fontWeight: 'bold'}}>Changed On: {dateChanged}</div>
                    </div>
                </div>
            )
        }
    }
}