import React, {useState, useContext} from "react";
import {UserContext} from '../context/UserProvider.js'
import pdfImg from '../imgs/pdfImg.png'
import axios from "axios";

const userAxios = axios.create()

userAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token')

    config.headers = {
        'Authorization': `Bearer ${token}`,
        'responseType': 'arraybuffer'    
    }

    config.headers.Authorization = `Bearer ${token}`
    return config
})

export default function UserRequestDisplay(props){
    const {request} = props

    const { 
        connectServerString,
        transProtocol,
        coloring
      } = useContext(UserContext)


    const dateRequested = new Date(request.requestDate).toLocaleString()

    const [imageRequested, setImageRequested] = useState(false)

    //const [imgSrc, setImgSrc] = useState(null)

    function toggleImage(){
        setImageRequested(prevState => (
            prevState = !prevState
        ))
    }

    function handleBrokenImage(e){
        e.target.src = pdfImg
    }

    // useEffect(() => {
    //     userAxios.get(`http://localhost:9000/api/access/userworkImages/${request._id}`)
    //     .then(res => {
    //       setImgSrc(res.data);
    //     });
    // }, [request._id]);
//https://picsum.photos/200

    // useEffect(() => {
    //     axios.get('https://picsum.photos/200')
    //     .then(res => {
    //         console.log(res)
    //         setImgSrc(res)
    //     })
    // }, [])

    // function getImage(request){
    //     userAxios.get(`http://localhost:9000/api/access/userworkImages/${request._id}`)
    //         .then(res => {
    //             console.log(res.headers)
    //         })
    // }

    return(
        <div className="requestDisplayContainer">
            <div autoFocus>
                <div>Job Type: {request.jobType}</div>
            </div>
            <div>
                <div className="statusDisplay">Status: <div className="statusText" style={{color: coloring(request.status)}}>{request.status}</div></div>
            </div>
            <div>
                <div>Description: {request.description}</div>
            </div>
            <div>
                <div>Date Requested: {dateRequested}</div>
            </div>
                        {!request.img ? <div>No Files Attached</div> : 
                            <div style={{display:'block'}}>
                                <button onClick = {toggleImage}>View Job Image</button>
                                <div>
                                {imageRequested ? <a href={`${transProtocol}://${connectServerString}/jobImage/Images/${request._id}`} target="_blank" rel="noopener noreferrer">
                                    <img className="jobImage" onError={handleBrokenImage} src={`${transProtocol}://${connectServerString}/jobImage/Images/${request._id}`} alt=''/>
                                    </a>: ''}
                                </div>
                            </div>
                        }   
        </div>
    )
}