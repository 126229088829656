import React, {useContext, useState} from "react";
import {UserContext} from '../context/UserProvider.js'


export default function EmailVerification(){

    const { 
        user,
        logout,
        generateOTP,
        verifyOTP
      } = useContext(UserContext)

    const [inputs, setInputs] = useState({
        otp:''
    })

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        verifyOTP(user._id, inputs)
    }

    return(
        <div style={{color: 'white'}}>
            <h3>Please verify email address to continue. Click <button className = 'requestBtn' style={{width: 70}} onClick={generateOTP}>HERE</button> to send verification code to {user.email}.</h3>
                <form onSubmit = {handleSubmit}>
                    <input
                        placeholder="Verification Code"
                        autoFocus
                        type="text"
                        name='otp'
                        value={inputs.otp}
                        onChange={handleChange}
                    />
                    <button className = 'requestBtn' style={{marginLeft: 20}} onClick={handleSubmit}>Verify Code</button>
                </form>
                
            <button className='logoutBtn' onClick = {logout}>Log Out</button>
        </div>
    )
}