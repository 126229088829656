import React, {useState} from "react";
import axios from 'axios'
import io from 'socket.io-client';

export const UserContext = React.createContext()

const connectServerString = 'dirtandseptic.com:9000'
// Alt for testing 'localhost:9000'
// Primary '185.148.129.168:9000'
// Primary 'dirtandseptic.com:9000'

const transProtocol = 'https'

const socket = io.connect(`https://dirtandseptic.com:9000`, {secure: true});
//const socket = io.connect(`${transProtocol}://${connectServerString}`);

const statusArray = [
    'Requested', 
    'Rejected', 
    'Approved to be Est', 
    'Scheduled for Est', 
    'Estimated',
    'Customer Accepted Est',
    'MISS UTILITY CLEARED',
    'PERMITTED',
    'Scheduled to Execute',
    'Started',
    'Completed'
]

const jobTypeArray = [
    'Septic',
    'Excavation',
    'Grading',
    'Trenching',
    'Landscaping',
    'Rock Delivery',
    'Other'
]

function coloring(status){
    if (status === 'Requested'){
        return "orange"
    }else if(status === 'Rejected'){
        return "red"
    }else if(status === 'Approved to be Est'){
        return 'green'
    }else if(status === 'Scheduled for Est'){
        return 'green'
    }else if(status === 'Estimated'){
        return "yellow"
    }else if(status === 'Customer Accepted Est'){
        return 'green'
    }else if(status === 'Scheduled to Execute'){
        return 'green'
    }else if(status === 'Started'){
        return 'green'
    }else if(status === 'Completed'){
        return "cyan"
    }else{
        console.log('unrecognized status')
    }
}

const userAxios = axios.create()

const userUploadAxios = axios.create()

userAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = `Bearer ${token}`
    return config
})

userUploadAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    
    config.headers = {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
    return config
})

export default function UserProvider(props){
    
    const initState = { 
        user: JSON.parse(localStorage.getItem("user")) || {},
        token: localStorage.getItem("token") || "",
        errMsg:'',
        resMsg: ''
    }

    const [userState, setUserState] = useState(initState)
    const [userRequests, setUsersRequests] = useState([])
    const [memberList, setMemberList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    function getUsersRequests(){
        loadingOn()
        userAxios.get(`${transProtocol}://${connectServerString}/api/access/userwork`)
            .then(res => {
                setUsersRequests(prevState => (
                    [
                        ...res.data
                    ]
                ))
                loadingOff()
            })
            .catch(err => handleAuthErr(err.message))
    }

    function loadingOff(){
        setIsLoading(false)
    }

    function loadingOn(){
        setIsLoading(true)
    }

    function updatePassword(userID, newPassword){
        loadingOn()
        userAxios.put(`${transProtocol}://${connectServerString}/api/access/changepassword/${userID}`, newPassword)
        .then((res => {const {user, token} = res.data
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            setUserState(prevUserState => ({
                ...prevUserState,
                user,
                token
            }))
            loadingOff()
        }))
        .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    function updateAddress(newAddress){
        loadingOn()
        userAxios.put(`${transProtocol}://${connectServerString}/api/access/updateaddress`, newAddress)
        .then((res => {const {user, token} = res.data
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            setUserState(prevUserState => ({
                ...prevUserState,
                user,
                token
            }))
            loadingOff()
        }))
        .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    function generateOTP(userID){
        loadingOn()
        userAxios.put(`${transProtocol}://${connectServerString}/api/access/generateotp/${userID}`)
        .then((res => {
            handleResMsg(res.data)
        }))
        .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    function verifyOTP(userID, otpToCheck){
        loadingOn()
        userAxios.put(`${transProtocol}://${connectServerString}/api/access/checkotp/${userID}`, otpToCheck)
        .then((res => {const {user, token} = res.data
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            setUserState(prevUserState => ({
                ...prevUserState,
                user,
                token
            }))
            loadingOff()
        }))
        .catch(err => handleAuthErr(err.response.data))
    }

    // function updateStatus(jobID, newStatus){
    //     userAxios.put(`${transProtocol}://${connectServerString}/api/access/jobstatus/${jobID}`, newStatus)
    //     .then((res => {
    //         console.log(res.data)
    //     }))
    //     .catch(err => handleAuthErr(err.response.data.errMsg))
    // }

    // function setInspectionDay(jobID, newStatus){
    //     userAxios.put(`${transProtocol}://${connectServerString}/api/access/jobinspection/${jobID}`, newStatus)
    //     .then((res => {
    //         console.log(res.data)
    //     }))
    //     .catch(err => handleAuthErr(err.response.data.errMsg))
    // }

    // function setEstimatedTimeFrame(jobID, newStatus){
    //     userAxios.put(`${transProtocol}://${connectServerString}/api/access/jobtimeframe/${jobID}`, newStatus)
    //     .then((res => {
    //         handleAuthErr(res.data)
    //     }))
    //     .catch(err => handleAuthErr(err.response.data.errMsg))
    // }

    function requestWork(workRequest){
        loadingOn()
        userUploadAxios.post(`${transProtocol}://${connectServerString}/api/access/work`, workRequest)
        .then((res => {
            handleResMsg(res.data)
        }))
        .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    function signup(credentials){
        loadingOn()
        axios.post(`${transProtocol}://${connectServerString}/auth/signup`, credentials)
        .then((res => {
            const {user, token} = res.data
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            setUserState(prevUserState => ({
                ...prevUserState,
                user,
                token
            }))
            loadingOff()
        }))
        .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    function login(credentials){
        loadingOn()
        axios.post(`${transProtocol}://${connectServerString}/auth/login`, credentials)
        .then((res => {const {user, token} = res.data
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            setUserState(prevUserState => ({
                ...prevUserState,
                user,
                token
            }))
            loadingOff()
        }))
        .catch(err => {
            handleAuthErr(err.response.data.errMsg)
        })
            
    }

    function logout(){
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        setUserState({
            user: {},
            token: ''
        })
    }

    function handleAuthErr(errMsg){
        setUserState(prevState => ({
            ...prevState,
            errMsg
        }))
    }

    function handleResMsg(resMsg){
        setUserState(prevState => ({
            ...prevState,
            resMsg
        }))
    }

    function resetResMsg(resMsg){
        setUserState(prevState => ({
            ...prevState,
            resMsg: ""
        }))
    }

    function resetAuthErr(){
        setUserState(prevState => ({
            ...prevState,
            errMsg: ""
        }))
    }

    function getMembers(){
        loadingOn()
        userAxios.get(`${transProtocol}://${connectServerString}/api/access/membersList`)
            .then(res => {
                setMemberList(prevState => (
                    [...res.data]
                ))
                loadingOff()
            })
            .catch(err => handleAuthErr(err.message))
    }

    return(
        <UserContext.Provider 
            value = {{
                ...userState,
                userRequests,
                signup,
                login,
                logout,
                handleAuthErr,
                resetAuthErr,
                handleResMsg,
                resetResMsg,
                updatePassword,
                updateAddress,
                requestWork,
                getUsersRequests,
                connectServerString,
                generateOTP,
                verifyOTP,
                socket,
                transProtocol,
                statusArray,
                coloring,
                isLoading,
                loadingOff,
                loadingOn,
                jobTypeArray,
                memberList,
                getMembers
            }}>
            {props.children}
        </UserContext.Provider>
    )
}