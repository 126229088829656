import React, {useState, useContext, useEffect} from "react";
import { UserContext } from './context/UserProvider.js'
import {Link} from 'react-router-dom';

export default function LoginForm(props){

    const {login, errMsg} = useContext(UserContext)

    const [showPass, setShowPass] = useState(false)

    const [inputs, setInputs] = useState({
        email:'',
        password:'',
    })
    
    function handleChange(e) {
        const { name, value } = e.target;
        if(name === 'email'){
            setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
        }else if(name === 'password'){
            setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
        }
        
    }

    function handleSubmit(e) {
        e.preventDefault();
        login(inputs)
    }

    function ToggleShow(){
        setShowPass(prevShowPass => !prevShowPass )
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    window.document.body.style.zoom = 1;

    return(
        <div className="loginFormContainer">
            <form onSubmit={handleSubmit} className = 'loginForm'>
                
                    <div>
                        <input
                            placeholder="Email Address"
                            autoFocus
                            type="text"
                            name='email'
                            value={inputs.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            placeholder="Password"
                            type={showPass ? "text" : "password"}
                            name='password'
                            value={inputs.password}
                            onChange={handleChange}
                        />
                        <div onClick={ToggleShow} style={{
                            display: 'inline-block',
                            position: 'absolute', 
                            marginLeft: -40,
                            height: 20,
                            paddingTop: 10,
                            fontSize: 12
                            }}>
                            {showPass ? 'Hide' : 'Show'}
                        </div>
                    </div>
                
                <p style = {{color:'red'}}>{errMsg}</p>
                <div><Link to='/ForgotPasswordForm'>Forgot Password?</Link></div>
                <button>Log In</button>
                <div className="divider">
                    <div>

                    </div>
                    <div>
                        OR
                    </div>
                    <div>

                    </div>
                </div>
                <div>
                    New User?
                </div>
                <div style={{margin:10, fontStyle:'Italic'}}>
                    <div><Link to='/accountCreateForm'>Create New Account</Link></div>
                </div>
            </form>
        </div>
    )
}