import React,{useContext} from "react";
import { UserContext } from "../context/UserProvider";
import ReactDOM from "react-dom";

export default function Loading(props){

    const { loadingOff, errMsg, resetAuthErr, resMsg, closer, resetResMsg } = useContext(UserContext)

    function turnLoadingOff(){
        if(closer){
            closer()
        }
        resetResMsg()
        resetAuthErr()
        loadingOff()
    }

    function msgSelect(){
        if(errMsg){
            return(
                <div className="errMsg">
                    <div>{errMsg}</div>
                    <div>
                        <button className="errBtn" onClick={turnLoadingOff}>OK</button>
                    </div>
                </div>
            )
        }else if(resMsg){
            return(
                <div className="resMsg">
                    <div>{resMsg}</div>
                    <div>
                        <button className="errBtn" onClick={turnLoadingOff}>OK</button>
                    </div>
                </div>
            )
        }else{
            return(
                <div className="lds-roller">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
            )
        }
    }

    return ReactDOM.createPortal(
        <div className="loading">
            
            {msgSelect()}
            
        </div>,
        document.getElementById("loadingScreen")
    )
}