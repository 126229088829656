import React from "react";

export default function ContactUs(props){
    return(
        <div className={props.className}>
            <h1>Contact Us</h1>
            <h2 style={{color: 'cyan'}}>{`Earl E. Preston Jr., Inc & Whiteford Septic Service`}</h2>
            <h4>4045-C Federal Hill Rd.</h4>
            <h4>Jarrettsville, MD 21084</h4>
            <h4>410-557-8100 (ph)</h4>
            <h4>410-692-9530 (fax)</h4>
            <a href='mailto: earljr@eprestonjr.com' className="emailUsLink">Email Us</a>
        </div>
    )
}