import React, {useContext, useState} from 'react';
import './App.css';
import Header from './Header';
import Menu from './Menu';
import LoginForm from './LoginForm';
import Home from './Home';
import ContactUs from './ContactUs';
import About from './About';
import AccountCreateForm from './components/AccountCreateForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
//import Footer from './components/Footer'
import {Routes, Route, Navigate} from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Account from './components/Account';
import { UserContext } from './context/UserProvider';
import Loading from './components/Loading';



function App() {
  const { token, isLoading } = useContext(UserContext)
  
  const tempToken = `${process.env.REACT_APP_MYENVVAR}`
  const [passed, setPassed] = useState(true)
  const [tokenInput, setTokenInput] = useState({
    pass:''
  })
  const [inputDisplay, setInputDisplay] = useState(false)

  function passMe(){
    setInputDisplay(prevState => !prevState)
    if(tokenInput.pass === tempToken){
      setPassed(true)
    }else{
      setPassed(false)
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    
        setTokenInput((prevInputs) => ({ ...prevInputs, [name]: value }));
        console.log(tokenInput)
  }

  if(passed){

  return (
    <div className='mainBody'>
      
      <Header className = 'headerMain'/>
      <Menu 
        className = 'menuMain'
        className2 = 'menuMainMobile'
        token = {token}
      />
        <div className='innerBodyContainer'>
          <Routes>
            <Route path = '/' element = {<Home/>}/>
            <Route path = '/login' element = {token ? <Navigate to="/account" /> :<LoginForm/>}/>
            <Route path = '/accountCreateForm' element = {token ? <Navigate to="/account" /> :<AccountCreateForm/>}/>
            <Route path = '/forgotPasswordForm' element = {token ? <Navigate to="/account" /> :<ForgotPasswordForm/>}/>
            <Route 
              path="/account"
              element = {<ProtectedRoute token = {token}>

                  <Account/>
                
                </ProtectedRoute>
              }
            />
            <Route path = '/contact' element = {<ContactUs className = 'contactContainer'/>}/>
            <Route path = '/about' element = {<About className = 'aboutContainer'/>}/>
          </Routes>
        </div>
        {isLoading ? <Loading/> : ''}
      </div>
  )
            }else{
              return(
                <div className='proxyBody'>
                  <div>
                    <button onClick={passMe} style={{opacity: inputDisplay ? 1 : 0, position: 'absolute', left: 0, top:0}}>CHECK</button>
                    <input style={{ display: inputDisplay? 'block' : 'none'}}
                      type='password'
                      onChange={handleChange}
                      name = 'pass'
                      value = {tokenInput.pass}
                    />
                  </div>
                  <h1>Site is Currently Under Construction.</h1>
                  <h2>Please come back later.</h2>
                </div>
              )
            }
}

export default App;
