import React from "react";

export default function AssignedMemberEdit(props){
    const {member, assignMember} = props

    return(
        <div className="AssignedMemberEdit">
            <div className="col" style={{paddingRight:10}}>{member.firstName} {member.lastName}</div>
            <button style = {{marginLeft: 10, marginRight:10}} onClick={assignMember}>Assign</button>
        </div>
    )
}