import React from 'react'

export default function Profile(props){

    const {user} = props
    
    return(
        <div className='profileContainer'>
            <div className='general'>
                <div>First Name: {user.firstName}</div>
                <div>Last Name: {user.lastName}</div>
                <div>E-mail: {user.email}</div>
            </div>
            <div className='address' style={{display: user.address.line1 !== '' ? 'block': 'none'}}>
                
                <div>Address:</div>
                <div style={{marginLeft: 20}}>
                    <div>{user.address.line1}</div>
                    {user.address.line2 ? <div>{user.address.line2}</div> : ''}
                    <div>{user.address.city}, {user.address.state}</div>
                    <div>{user.address.zip}</div>
                </div>
            </div>
        </div>
    )
}