import React, {useState, useContext} from "react";
import { UserContext } from "../context/UserProvider";
import axios from "axios";
import { Link } from "react-router-dom";

export default function ForgotPasswordForm(){

    const [userEmail, setUserEmail] = useState('')
    
    const {
        connectServerString,
        transProtocol,
        loadingOn,
        handleAuthErr,
        handleResMsg
    } = useContext(UserContext)


    function forgotPassword(userEmail){
        loadingOn()
        axios.put(`${transProtocol}://${connectServerString}/forgotpassword/requestpassword/${userEmail}`)
        .then((res => {
            handleResMsg(res.data)
        }))
        .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setUserEmail((prevUserEmail) => ({ ...prevUserEmail, [name]: value }));
    }

    function handleSubmit(e){
        e.preventDefault();
        forgotPassword(userEmail.email)
    }

    return(
        <div>
            <h2 style={{color: 'white'}}>Please enter email for account recovery</h2>
            <form onSubmit={handleSubmit}>
                <input
                    onChange={handleChange}
                    type = 'text'
                    name = 'email'
                />
                <button className = 'requestBtn' style={{marginLeft: 20}}>Send Email</button>
            </form>
            <div><Link to='/login'>Back to Login</Link></div>
        </div>
    )
}